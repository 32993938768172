import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Form,
  Header,
  Divider,
  Icon,
  Message,
  Dropdown,
  Image,
  Loader,
  Button,
  Label,
  Modal,
  Input,
  Checkbox,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
import { states } from "../states";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  ADD_CUSTOMER,
  THEATERS,
  CIRCUITS,
  CAMPAIGNS,
  TITLES,
  STUDIOS,
} from "../graphQueries";
import "animate.css";
import moment from "moment-timezone";
import "./intakeStyle.css";
import { industries } from "./industries";
import GatewayModal from "./GatewayModal";
import IntakeForm from "./IntakeForm";
import SelectTitle from "./SelectTitle";
import ExpiredCampaign from "./ExpiredCampaign";
import PrematureCampaign from "./PrematureCampaign";
import GroupError from "./GroupError";
import { location } from "./location";
import RegionLocked from "./RegionLocked";
import { Helmet } from "react-helmet";

const Intake = () => {
  const [company, setCompany] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [industry, setIndustry] = useState("");
  const [date, setDate] = useState("");
  const [comments, setComments] = useState("");
  const [groupSize, setGroupSize] = useState("");
  const [checked, setChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [theater, setTheater] = useState(null);
  const [industry2, setIndustry2] = useState("");
  const [industry3, setIndustry3] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [circuitsList, setCircuitsList] = useState([]);
  const [circuitId, setCurcuitId] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchRadius, setSearchRadius] = useState("8047");
  const [searchBy, setSearchBy] = useState("zip");
  const [title, setTitle] = useState("");
  const [market, setMarket] = useState("");
  const [formFocus, setFormFocus] = useState(false);
  const [isPremature, setPremature] = useState(false);
  const [errorFields, setErrorFields] = useState({
    firstName: false,
    lastName: false,
    company: false,
    industry: false,
    address: false,
    city: false,
    state: false,
    zipCode: false,
    phone: false,
    email: false,
    date: false,
    title: false,
    theater: false,
    groupSize: false,
    checked: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [groupSizeError, setGroupError] = useState("");
  const [amcError, setAmcError] = useState(false);
  const [regalError, setRegalError] = useState(false);
  const [cinemarkError, setCinemarkError] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [campaign, setCampaign] = useState({});
  const [campaignsList, setCampaigns] = useState([]);
  const [initializing, setInit] = useState(true);
  const [isGateway, setIsGateway] = useState(false);
  const [isGatewayDecline, setIsGatewayDecline] = useState(false);
  const [isGatewayEnabled, setIsGatewayEnabled] = useState(false);
  const [titleList, setTitleList] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState({});
  const [isSelectTitle, setIsSelectTitle] = useState(false);
  const [isExpired, setExpired] = useState(false);
  const [isStudioPage, setStudioPage] = useState(false);
  const [studioObj, setStudioObj] = useState({});
  const [locationFail, setLocationFail] = useState(false);
  const [isNoResults, setNoResults] = useState(false);
  const isMobile = windowWidth < 930;
  const searchByOptions = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Zip Code",
      value: "zip",
    },
  ];
  const radiusOptions = [
    {
      text: "1 Mile",
      value: "1609",
    },
    {
      text: "3 Miles",
      value: "4828",
    },
    {
      text: "5 Miles",
      value: "8047",
    },
    {
      text: "10 Miles",
      value: "16093",
    },
    {
      text: "15 Miles",
      value: "24140",
    },
    {
      text: "20 Miles",
      value: "32187",
    },
    {
      text: "25 Miles",
      value: "40234",
    },
  ];

  const defaultThankYou =
    "Thank you for your inquiry. A representative will get back to you within 48 hours.";
  const defaultTerms = `By clicking SUBMIT, I agree to receive phone calls, email, and mail with ticketing and group ticket sales information and updates, I acknowledge that my information will be handled and used by ${campaign.studioName} in accordance with the ${campaign.studioName} Privacy Policy, and I agree to such handling and use by ${campaign.studioName}.`;
  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
  }, []);
  const handleResizeEvent = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    const localeFunc = async () => {
      const loc = await location();
      console.log(loc);
      if (loc.country !== "United States") {
        setLocationFail(true);
      }
    };
    localeFunc();
  }, []);

  useEffect(() => {
    document.cookie = "onetrust=grouptickets-wds; max-age=86400; path=/intake;";
  }, []);

  // useEffect(() => {
  //   const buttonElement = document.getElementsByClassName(
  //     "save-preference-btn-handler"
  //   );
  //   if (buttonElement.length) {
  //     console.log(buttonElement);
  //     buttonElement[0].addEventListener("click", () => handleConfirmChoices());
  //   }
  // }, []);

  // const handleConfirmChoices = () => {
  //   const toggle = document.getElementById("ot-group-id-SSPD_BG");
  //   if (toggle) {
  //     if (toggle.checked) {
  //     } else {
  //       console.log("update the cookie");
  //       document.cookie = "onetrust=; max-age=-1; path=/intake;";
  //     }
  //   }
  // };

  useEffect(() => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const titleId = urlParams.get("titleId") || urlParams.get("titleld");
    const campaignId = urlParams.get("campaignId");
    const studioId = urlParams.get("studioId");
    if (studioId) {
      studioCampaigns({ variables: { studioId: studioId } });
      setStudioPage(true);
      studios({ variables: { studioId } });
      return;
    }
    if (campaignId) {
      campaigns({ variables: { campaignId: campaignId } });
      return;
    }
    setTitle(titleId);
    campaigns({ variables: { titleId: titleId } });
  }, []);

  useEffect(() => {
    circuits();
  }, []);

  useEffect(() => {
    if (campaign.gateway) {
      setIsGateway(true);
    }
    if (campaign.defaultRadius) {
      setSearchRadius(campaign.defaultRadius);
    }
  }, [campaign]);

  useEffect(() => {
    console.log("campaign", campaign, "title", selectedTitle);
  }, [campaign, selectedTitle]);

  const [addCustomer] = useMutation(ADD_CUSTOMER, {
    onCompleted: (result) => handleAddCustomer_Result(result),
    onError: (error) => handleAddCustomer_Error(error),
  });

  const [theaters] = useLazyQuery(THEATERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTheaters_Result(result),
    onError: (error) => handleTheaters_Error(error),
  });

  const [circuits] = useLazyQuery(CIRCUITS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCircuits_Result(result),
    onError: (error) => handleCircuits_Error(error),
  });

  const [titles] = useLazyQuery(TITLES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTitles_Result(result),
    onError: (error) => handleTitles_Error(error),
  });

  const [studioCampaigns] = useLazyQuery(CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleStudioCampaigns_Result(result),
    onError: (error) => handleStudioCampaigns_Error(error),
  });

  const [campaigns] = useLazyQuery(CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCampaigns_Result(result),
    onError: (error) => handleCampaigns_Error(error),
  });

  const [studios] = useLazyQuery(STUDIOS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleStudios_Result(result),
    onError: (error) => handleStudios_Error(error),
  });

  const handleStudios_Result = (result) => {
    console.log(result);
    if (result && result.studios.length) {
      setStudioObj(result.studios[0]);
    }
  };

  const handleStudios_Error = (error) => {
    console.log(error);
  };

  const handleStudioCampaigns_Result = (result) => {
    console.log("handleStudioCampaigns_Result", result);
    if (result && result.campaigns) {
      setCampaigns(result.campaigns);
      let titleIds = [];
      for (const c of result.campaigns) {
        titleIds.push(c.titleId);
      }
      console.log(titleIds.join(","));
      titles({ variables: { titleId: titleIds.join(",") } });
    }
  };

  const handleStudioCampaigns_Error = (error) => {
    console.log(error);
  };

  const handleCampaigns_Result = (result) => {
    console.log("handleCampaigns_Result", result);
    if (result && result.campaigns.length) {
      console.log(result);
      setCampaign(result.campaigns[0]);
      titles({ variables: { titleId: result.campaigns[0].titleId } });
    }
  };

  const handleCampaigns_Error = (error) => {
    console.log(error);
  };

  const handleCircuits_Result = (result) => {
    console.log(result);
    if (result.circuits && result.circuits.length) {
      setCircuitsList(result.circuits);
    }
  };

  const handleCircuits_Error = (error) => {
    console.log(error);
  };

  const handleTitles_Result = (result) => {
    console.log("titles result", result);
    if (result.titles) {
      let array = result.titles.map((item) =>
        Object.assign({}, item, { text: item.name, value: item.titleId })
      );
      setTitleList(array);
      if (array.length === 1) {
        console.log("inside just one title");
        setSelectedTitle(array[0]);
        setTitle(array[0].titleId);
        const updateCamp = { ...campaign };
        Object.assign(updateCamp, {
          amcText: array[0].amcText,
          regalText: array[0].regalText,
          cinemarkText: array[0].cinemarkText,
          amcThreshold: array[0].amcThreshold,
          regalThreshold: array[0].regalThreshold,
          cinemarkThreshold: array[0].cinemarkThreshold,
          defaultThreshold: array[0].defaultThreshold,
        });
        setCampaign(updateCamp);
      } else {
        setIsSelectTitle(true);
      }
      // check if any title is expired, and expire the whole form, unlikely for any other condition to be needed but may need to revist if there is a scenario where one title is expired but another is not.
      for (const t of array) {
        if (t.endDate && moment(t.endDate).endOf("day") < moment()) {
          // it is expired
          // setExpired(true);
          Object.assign(t, { expired: true });
          if (array.length === 1) {
            setExpired(true);
          }
          console.log("expired!");
        }
        // if (moment(t.startDate) > moment()) {
        //   // this hasn't started yet, prevent them from using the form.
        //   Object.assign(t, { premature: true });
        //   if (array.length === 1) {
        //     setPremature(true);
        //   }
        // }
      }
      setTitleList(array);
      setInit(false);
    }
  };

  const handleTitles_Error = (error) => {
    console.log(error);
  };
  const disney = campaign.campaignId === "66106079cdeef3fb4a65a396";

  useEffect(() => {
    if (!disney) {
      const element = document.getElementById("onetrust-consent-sdk");
      if (element) {
        element.style.display = "none";
      }
    }
  }, []);

  const handleAddCustomer = () => {
    // special handling for markets only scenario. need to assign No Theater to bypass form validation
    let noTheater = "";
    let noCircuit = "";
    let isTheater = theater;
    let isChecked = checked;
    if (disney) {
      isChecked = true;
    }
    if (campaign.marketsOnly) {
      noTheater = "6577b87881504313e34cbcc7";
      noCircuit = "6577b8335ae288796caf23f3";
      setTheater({ theaterId: "6577b87881504313e34cbcc7" });
      setCurcuitId("6577b8335ae288796caf23f3");
      isTheater = noTheater;
    }
    let time = true;
    if (moment(date).format("HH") === "00") {
      time = false;
    }
    if (
      company &&
      firstName &&
      lastName &&
      industry &&
      address &&
      city &&
      state &&
      zipCode &&
      phone &&
      email &&
      date &&
      groupSize > 19 &&
      isTheater &&
      title &&
      isChecked &&
      time
    ) {
      setLoading(true);
      const time = moment(date).format();
      const abbrv = time.slice(0, 19);
      setTimeout(() => {
        addCustomer({
          variables: {
            firstName,
            lastName,
            company,
            industry: `${industry} ${industry2} ${industry3}`.trim(),
            address,
            city,
            state,
            zipCode,
            phone,
            email,
            date: abbrv,
            groupSize,
            theaterId: noTheater || theater.theaterId,
            studioId: campaign.studioId,
            circuitId: noCircuit || circuitId,
            titleId: selectedTitle.titleId,
            campaignId: campaign.campaignId,
            market,
            comments,
          },
        });
      }, 1000);
    } else {
      let errors = { ...errorFields };
      if (!firstName) {
        Object.assign(errors, { firstName: true });
      }
      if (!lastName) {
        Object.assign(errors, { lastName: true });
      }
      if (!company) {
        Object.assign(errors, { company: true });
      }
      if (!industry) {
        Object.assign(errors, { industry: true });
      }
      if (!address) {
        Object.assign(errors, { address: true });
      }
      if (!city) {
        Object.assign(errors, { city: true });
      }
      if (!state) {
        Object.assign(errors, { state: true });
      }
      if (!zipCode) {
        Object.assign(errors, { zipCode: true });
      }
      if (!phone) {
        Object.assign(errors, { phone: true });
      }
      if (!email) {
        Object.assign(errors, { email: true });
      }
      if (!date) {
        Object.assign(errors, { date: true });
      }
      if (!groupSize) {
        Object.assign(errors, { groupSize: true });
      }
      if (!title) {
        Object.assign(errors, { title: true });
      }
      if (!isTheater) {
        Object.assign(errors, { theater: true });
      }
      if (!isChecked) {
        Object.assign(errors, { checked: true });
      }
      if (!time) {
        Object.assign(errors, { date: true });
      }
      console.log(errors);
      setErrorFields(errors);
      setErrorMessage("Please fill out all required fields.");
      setLoading(false);
    }
  };

  const handleAddCustomer_Result = (result) => {
    if (
      result &&
      result.addCustomer &&
      result.addCustomer.status === "succeeded"
    ) {
      setSuccess(true);
      setLoading(false);
    }
  };

  const handleAddCustomer_Error = (error) => {
    console.log(error);
  };

  const handleSelectState = (e, data) => {
    setState(data.value);
    resetErrors("state");
  };

  const handleTheaters_Result = (result) => {
    setSearching(false);
    if (result.theaters && result.theaters.length) {
      setSearchResults(result.theaters);
    } else {
      setNoResults(true);
    }
  };

  const handleTheaters_Error = (error) => {
    console.log(error);
    setSearching(false);
    setErrorMessage(`No theaters found for ${searchValue}`);
  };

  const handleTheaterSearchValue = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setErrorMessage("");
    resetErrors("theater");
    setSearchResults([]);
    setNoResults(false);
  };

  const handleTheaterSearch = () => {
    setSearching(true);
    // run titles query for limited list of theaters

    if (searchBy === "zip") {
      theaters({
        variables: {
          zip: searchValue,
          radius: searchRadius,
          campaignId: campaign.limitedTheaters ? campaign.campaignId : null,
        },
      });
    }
    if (searchBy === "name") {
      theaters({
        variables: {
          name: searchValue,
          campaignId: campaign.limitedTheaters ? campaign.campaignId : null,
        },
      });
    }
  };

  const handleSelectTheater = (theaterObj) => {
    console.log(theaterObj);
    resetErrors("theater");
    setTheater(theaterObj);
    // find circuit ID
    const circuit = circuitsList.filter(
      (x) => x.circuitId === theaterObj.circuitId
    );
    setCurcuitId(circuit[0].circuitId);
    setSearchResults([]);
    setNoResults(false);
  };

  const resetErrors = (item) => {
    let obj = { ...errorFields };
    Object.assign(obj, { [item]: false });
    setErrorFields(obj);
    setErrorMessage("");
  };

  const handleGroupSizeCheck = () => {
    let isAMC = false;
    let isRegal = false;
    let isCinemark = false;
    const amcThreshold = parseInt(campaign.amcThreshold) + 1 || 31;
    const regalThreshold = parseInt(campaign.regalThreshold) + 1 || 31;
    const cinemarkThreshold = parseInt(campaign.cinemarkThreshold) + 1 || 31;
    const defaultThreshold = parseInt(campaign.defaultThreshold) + 1 || 21;
    if (theater) {
      isAMC = theater.circuit === "AMC";
      isRegal = theater.circuit === "REGAL";
      isCinemark = theater.circuit === "CINEMARK";
    }
    let sizeString = parseInt(groupSize);
    if (groupSize && groupSize.includes("-")) {
      const array = groupSize.split("-");
      sizeString = parseInt(array[0]);
    }
    if (sizeString > 0 && sizeString < amcThreshold && isAMC) {
      setAmcError(true);
      let obj = { ...errorFields };
      Object.assign(obj, { groupSize: true });
      setErrorFields(obj);
      return;
    }
    if (sizeString > 0 && sizeString < regalThreshold && isRegal) {
      setRegalError(true);
      let obj = { ...errorFields };
      Object.assign(obj, { groupSize: true });
      setErrorFields(obj);
      return;
    }
    if (sizeString > 0 && sizeString < cinemarkThreshold && isCinemark) {
      setCinemarkError(true);
      let obj = { ...errorFields };
      Object.assign(obj, { groupSize: true });
      setErrorFields(obj);
      return;
    }
    if (sizeString > 0 && sizeString < defaultThreshold) {
      setGroupError(
        ` For parties smaller than ${
          defaultThreshold - 1
        }, please contact your local affiliate directly.`
      );
      let obj = { ...errorFields };
      Object.assign(obj, { groupSize: true });
      setErrorFields(obj);
      console.log("Group Size is invalid:", sizeString);
    } else {
      setGroupError("");
      let obj = { ...errorFields };
      Object.assign(obj, { groupSize: false });
      setErrorFields(obj);
      console.log("Group Size is valid:", sizeString);
    }
  };

  const handleChangeRadius = (e, data) => {
    console.log(data.value);
    setSearchRadius(data.value);
    setSearchResults([]);
    setNoResults(false);
  };

  const handleChangeGroupSize = (e) => {
    setAmcError(false);
    const value = e.target.value;
    if (value === "") {
      setGroupSize(value);
      return;
    }
    const re = new RegExp("^[0-9 -]+$");
    if (re.test(value)) {
      setGroupSize(value);
    }
  };

  const handleChangeSearchBy = (e, data) => {
    console.log(data.value);
    setSearchBy(data.value);
    setSearchResults([]);
    setNoResults(false);
    setSearchValue("");
  };

  const handleClearTheaterSelection = () => {
    setSearchResults([]);
    setNoResults(false);
    setSearchValue("");
    setTheater(null);
  };

  const handleScroll = (e) => {
    const element = document.getElementById("gatewayNode");
    if (element) {
      const threshold = element.scrollTop + element.clientHeight + 10;
      if (element.scrollHeight <= threshold) {
        setIsGatewayEnabled(true);
      }
    }
  };

  const handleChangeTitle = (e, data) => {
    console.log(data);
    // const title = titleList.filter((x) => x.titleId === data.value);
    setSelectedTitle(data);
    setTitle(data.value);
    // set campaign if this is a studio page
    if (isStudioPage) {
      const camp = campaignsList.filter((x) => x.titleId.includes(data.value));
      if (camp.length) {
        const obj = { ...camp[0] };
        Object.assign(obj, {
          amcText: data.amcText,
          regalText: data.regalText,
          cinemarkText: data.cinemarkText,
          amcThreshold: data.amcThreshold,
          regalThreshold: data.regalThreshold,
          cinemarkThreshold: data.cinemarkThreshold,
          defaultThreshold: data.defaultThreshold,
        });
        setCampaign(obj);
      }
    } else {
      const updateCamp = { ...campaign };
      Object.assign(updateCamp, {
        amcText: data.amcText,
        regalText: data.regalText,
        cinemarkText: data.cinemarkText,
        amcThreshold: data.amcThreshold,
        regalThreshold: data.regalThreshold,
        cinemarkThreshold: data.cinemarkThreshold,
        defaultThreshold: data.defaultThreshold,
      });
      setCampaign(updateCamp);
    }
  };

  const handleChangeMarket = (e, data) => {
    setMarket(data.value);
  };

  const getExcludedTimes = () => {
    // if (campaign.campaignId !== "66106079cdeef3fb4a65a396") return [];
    const startTime = moment(selectedTitle.startDate).format("HH");
    if (!startTime) return [];
    const hoursArray = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23,
    ];
    const hoursBefore = hoursArray.filter((x) => x < parseInt(startTime));
    let times = [];
    for (const h of hoursBefore) {
      times.push(setHours(setMinutes(new Date(selectedTitle.startDate), 0), h));
      times.push(
        setHours(setMinutes(new Date(selectedTitle.startDate), 30), h)
      );
    }
    // let times = [
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 1),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 1),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 2),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 2),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 3),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 3),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 4),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 4),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 5),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 5),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 6),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 6),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 7),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 7),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 8),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 8),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 9),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 9),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 10),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 10),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 11),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 11),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 12),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 12),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 13),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 13),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 0), 14),
    //   setHours(setMinutes(new Date(selectedTitle.startDate), 30), 14),
    // ];
    const start = moment(selectedTitle.startDate).format("YYYY-MM-DD");
    const selected = moment(date).format("YYYY-MM-DD");
    if (start === selected) {
      return times;
    } else {
      return [];
    }
  };

  const getExcludeDates = () => {
    let array = [];
    let loop = 0;
    const loopTimes = parseInt(campaign.nearSelectionDays) || 7;
    while (loop <= loopTimes) {
      array.push(new Date(moment().add(loop, "days")));
      loop++;
    }
    console.log(array);
    return array;
  };

  const handleDismissError = () => {
    setAmcError(false);
    setRegalError(false);
    setCinemarkError(false);
  };

  const handleSetComments = (value) => {
    if (value.length < 301) {
      setComments(value);
    } else {
      return;
    }
  };

  const SearchResults = () => {
    if (searchResults.length) {
      return searchResults.map((item, index) => {
        return (
          <Dropdown.Item key={index} onClick={() => handleSelectTheater(item)}>
            <Header>
              {item.name}
              <Header.Subheader>
                {item.city}, {item.state} {item.zipCode}
              </Header.Subheader>
            </Header>
          </Dropdown.Item>
        );
      });
    } else if (isNoResults) {
      <Dropdown.Item>
        <Header content="No Results" />
      </Dropdown.Item>;
    }
  };

  const sideLayout =
    campaign.layout === "layout2" || campaign.layout === "layout3";

  return (
    <div style={{ backgroundColor: "#000000", height: "100%" }}>
      {/* {campaign.campaignId === "6647ab09cc48654d50fb0010" && (
        <Helmet>
          <title>Reagan</title>
          <meta property="og:image" content={campaign.artworkLink} />
          <meta property="og:title" content="Reagan" />
          <meta
            property="og:description"
            content={campaign.formHeading || "Request Group Tickets"}
          />
        </Helmet>
      )} */}
      {disney && (
        <div
          style={{
            position: "absolute",
            minHeight: "100px",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* <Header
            inverted
            content="GROUP TICKETS SALES"
            style={{
              margin: "10px",
              fontSize: "2em",
            }}
          /> */}
          <Image
            style={{ width: "400px", margin: "10px" }}
            src="/images/youngwoman_1200.png"
          />
          <Image
            style={{ width: "300px", margin: "10px" }}
            src="/images/groupticketsales.png"
          />

          <p
            style={{
              maxWidth: "840px",
              color: "#FFFFFF",
              padding: "10px",
              textAlign: "center",
            }}
          >
            Daisy Ridley stars as the accomplished swimmer who was born to
            immigrant parents in New York City in 1905. Through the steadfast
            support of her older sister and supportive trainers, she overcame
            adversity and the animosity of a patriarchal society to rise through
            the ranks of the Olympic swimming team and complete the staggering
            achievement – a 21-mile trek from France to England.
          </p>
        </div>
      )}
      <div
        className="animate__animated animate__fadeIn"
        style={{
          height: windowHeight,
          width: "100vw",
          backgroundImage: sideLayout ? `url(${campaign.artworkLink})` : null,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition:
            campaign.layout === "layout3" ? "right top" : "left top",
          backgroundColor: "#000000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          position: "absolute",
          zIndex: 1,
        }}
      >
        {isSelectTitle && !isExpired && !isPremature && (
          <SelectTitle
            selectedTitle={selectedTitle}
            setIsSelectTitle={setIsSelectTitle}
            titleList={titleList}
            handleChangeTitle={handleChangeTitle}
            isStudioPage={isStudioPage}
            studioObj={studioObj}
          />
        )}
        {isGateway && !isExpired && !isPremature && (
          <GatewayModal
            handleScroll={handleScroll}
            campaign={campaign}
            isGatewayEnabled={isGatewayEnabled}
            setIsGateway={setIsGateway}
            setIsGatewayDecline={setIsGatewayDecline}
            isGatewayDecline={isGatewayDecline}
          />
        )}
        {initializing && <Loader active />}
        {locationFail && <RegionLocked />}
        {!initializing &&
          !locationFail &&
          !isGateway &&
          !isSelectTitle &&
          !isExpired &&
          !isPremature && (
            <IntakeForm
              campaign={campaign}
              address={address}
              checked={checked}
              city={city}
              company={company}
              comments={comments}
              date={date}
              DatePicker={DatePicker}
              defaultTerms={defaultTerms}
              defaultThankYou={defaultThankYou}
              email={email}
              errorFields={errorFields}
              errorMessage={errorMessage}
              firstName={firstName}
              formFocus={formFocus}
              groupSize={groupSize}
              groupSizeError={groupSizeError}
              handleAddCustomer={handleAddCustomer}
              handleChangeGroupSize={handleChangeGroupSize}
              handleChangeRadius={handleChangeRadius}
              handleChangeSearchBy={handleChangeSearchBy}
              handleClearTheaterSelection={handleClearTheaterSelection}
              handleGroupSizeCheck={handleGroupSizeCheck}
              handleSelectState={handleSelectState}
              handleTheaterSearch={handleTheaterSearch}
              handleTheaterSearchValue={handleTheaterSearchValue}
              industries={industries}
              industry={industry}
              industry2={industry2}
              industry3={industry3}
              isMobile={isMobile}
              lastName={lastName}
              loading={loading}
              moment={moment}
              phone={phone}
              radiusOptions={radiusOptions}
              resetErrors={resetErrors}
              searchBy={searchBy}
              searchByOptions={searchByOptions}
              searching={searching}
              searchRadius={searchRadius}
              searchResults={searchResults}
              SearchResults={SearchResults}
              searchValue={searchValue}
              setAddress={setAddress}
              setChecked={setChecked}
              setCity={setCity}
              setCompany={setCompany}
              handleSetComments={handleSetComments}
              setDate={setDate}
              setEmail={setEmail}
              setFirstName={setFirstName}
              setFormFocus={setFormFocus}
              setHours={setHours}
              setIndustry={setIndustry}
              setIndustry2={setIndustry2}
              setIndustry3={setIndustry3}
              setLastName={setLastName}
              setMinutes={setMinutes}
              setPhone={setPhone}
              setZipCode={setZipCode}
              state={state}
              states={states}
              success={success}
              theater={theater}
              windowHeight={windowHeight}
              zipCode={zipCode}
              selectedTitle={selectedTitle}
              sideLayout={sideLayout}
              getExcludedTimes={getExcludedTimes}
              onMarketChange={handleChangeMarket}
              market={market}
              disney={disney}
              isNoResults={isNoResults}
              getExcludeDates={getExcludeDates}
            />
          )}
        {isExpired && <ExpiredCampaign customText={campaign.expiredText} />}
        {isPremature && <PrematureCampaign />}
        {amcError && (
          <GroupError
            amcError={amcError}
            handleDismissError={handleDismissError}
            campaign={campaign}
          />
        )}
        {regalError && (
          <GroupError
            regalError={regalError}
            handleDismissError={handleDismissError}
            campaign={campaign}
          />
        )}
        {cinemarkError && (
          <GroupError
            cinemarkError={cinemarkError}
            handleDismissError={handleDismissError}
            campaign={campaign}
          />
        )}
      </div>
    </div>
  );
};
export default Intake;
