import {
  Header,
  Image,
  Divider,
  Icon,
  Input,
  Checkbox,
  Dropdown,
  Button,
  Label,
  Message,
  Form,
} from "semantic-ui-react";
import { useState, useRef, useEffect } from "react";
// import { Anchorme } from "react-anchorme";
import AutoLinkText from "react-autolink-text2";
import ReactPlayer from "react-player";
const IntakeForm = (props) => {
  const [controlsVisible, setControls] = useState(false);
  const [ready, setReady] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [videoWidth, setVideoWidth] = useState("590px");
  const [videoHeight, setVideoHeight] = useState("360px");
  const [showTime, setShowTime] = useState(false);

  const playerRef = useRef();

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    handleResizeEvent();
  });

  const {
    campaign,
    isMobile,
    windowHeight,
    formFocus,
    success,
    setFormFocus,
    errorFields,
    company,
    setCompany,
    resetErrors,
    industry,
    industries,
    setIndustry,
    industry2,
    industry3,
    setIndustry2,
    setIndustry3,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    address,
    setAddress,
    city,
    comments,
    setCity,
    state,
    states,
    handleSelectState,
    zipCode,
    setZipCode,
    phone,
    setPhone,
    email,
    setEmail,
    theater,
    searchBy,
    searchByOptions,
    handleChangeSearchBy,
    searchValue,
    handleTheaterSearch,
    handleTheaterSearchValue,
    searchResults,
    SearchResults,
    searchRadius,
    radiusOptions,
    handleChangeRadius,
    searching,
    handleClearTheaterSelection,
    DatePicker,
    date,
    setDate,
    setHours,
    setMinutes,
    moment,
    groupSize,
    handleChangeGroupSize,
    handleGroupSizeCheck,
    groupSizeError,
    errorMessage,
    defaultTerms,
    checked,
    setChecked,
    loading,
    handleAddCustomer,
    defaultThankYou,
    selectedTitle,
    sideLayout,
    getExcludedTimes,
    onMarketChange,
    market,
    disney,
    handleSetComments,
    isNoResults,
    getExcludeDates,
  } = props;
  const imax = window.location.href.includes("imax.com");
  const handleFooterClick = () => {
    window.semaphore.push(["showPreferences"]);
  };
  const getMarkets = () => {
    const array = campaign.markets.split(",");
    let finalArray = [];
    for (const a of array) {
      const obj = {
        text: a,
        value: a,
      };
      finalArray.push(obj);
    }
    return finalArray;
  };

  const handleMouseEnter = () => {
    setControls(true);
  };

  const handleMouseExit = () => {
    setControls(false);
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(true);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    playerRef.current.seekTo(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  const handleResizeEvent = (e) => {
    const element = document.getElementById("boundingElement");
    if (element) {
      setVideoWidth(element.offsetWidth + "px");
      setVideoHeight(element.offsetHeight + "px");
    }
  };

  const getMinDate = (startDate) => {
    const now = new Date();
    const start = new Date(startDate);
    if (start > now) {
      return start;
    } else {
      return now;
    }
  };

  const flexWithTrailer = campaign.trailerUrl ? "center" : "flex-end";

  return (
    <div
      id="intakeForm"
      style={{
        display: "flex",
        justifyContent:
          campaign.layout === "layout2"
            ? flexWithTrailer
            : campaign.layout === "layout3"
            ? "flex-start"
            : "center",
        alignItems: "center",
        maxWidth: isMobile ? "100%" : sideLayout ? "95%" : "1000px",
        width: "100%",
        height: disney ? "calc(100% - 220px)" : "100%",
        flexWrap: isMobile ? "wrap" : "nowrap",
        zIndex: 3,
        position: "absolute",
        top: disney && isMobile ? "320px" : disney ? "220px" : "0px",
      }}
    >
      {!sideLayout && (
        <div>
          <Image
            className="animate__animated animate__backInLeft"
            src={campaign.artworkLink}
            style={{
              border: "1px solid white",
              margin: isMobile ? "" : "-5px",
              objectFit: "contain",
              maxHeight: windowHeight - 100,
              width: "100%",
            }}
          />
        </div>
      )}
      <div>
        {campaign.trailerUrl && (
          <div
            id="boundingElement"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseExit}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <div
              style={{
                opacity: !playing || controlsVisible ? 1 : 0,
                transition: "opacity 0.25s",
                backgroundColor:
                  !playing || controlsVisible
                    ? "rgba(0,0,0,0.5)"
                    : "rgba(0,0,0,0)",
                width: videoWidth,
                height: videoHeight,
                position: "absolute",
                display: ready ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "850px",
              }}
            >
              <Icon
                className="control-button"
                style={{ color: "#FFFFFF", zIndex: 1 }}
                size="huge"
                name={playing ? "pause" : "play"}
                onClick={() => setPlaying(!playing)}
              />
              <Icon
                className="control-button"
                style={{
                  color: "#FFFFFF",
                  zIndex: 1,
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                size="large"
                name={muted ? "volume off" : "volume up"}
                onClick={() => setMuted(!muted)}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  width: "95%",
                  zIndex: 1,
                }}
              >
                <input
                  style={{ width: "100%", zIndex: 1 }}
                  type="range"
                  min={0}
                  max={0.999999}
                  step="any"
                  value={played}
                  onMouseDown={handleSeekMouseDown}
                  onChange={handleSeekChange}
                  onMouseUp={handleSeekMouseUp}
                />
              </div>
            </div>

            <ReactPlayer
              width="100%"
              ref={playerRef}
              playing={playing}
              muted={muted}
              url={campaign.trailerUrl}
              onEnded={() => setPlaying(false)}
              onProgress={handleProgress}
              onReady={() => {
                handleResizeEvent();
                setReady(true);
              }}
            />
          </div>
        )}
      </div>
      <div
        className={
          campaign.layout === "layout3"
            ? "animate__animated animate__slideInLeft"
            : "animate__animated animate__slideInRight"
        }
        style={{
          backgroundColor: formFocus
            ? "rgb(241,241,241, 1)"
            : "rgb(241,241,241, 0.8)",
          transition: "background-color 0.5s ease",
          maxWidth: isMobile ? "100%" : "410px",
          width: "100%",
          height: success ? "" : isMobile ? "" : "60%",
          borderRadius: isMobile ? "" : "10px",
          padding: "15px",
          overflowY: "auto",
          boxShadow:
            campaign.layout === "layout2"
              ? "-4px 4px 11px 0px rgba(0,0,0,0.45)"
              : "4px 4px 11px 0px rgba(0,0,0,0.45)",
        }}
      >
        {!success ? (
          <div>
            <Header
              as="h1"
              style={{
                fontWeight: 800,
                marginTop: "10px",
                textAlign: "center",
                marginBottom: "30px",
                color: formFocus ? campaign.labelColor : "",
              }}
            >
              {campaign.logoLink ? (
                <Image src={campaign.logoLink} style={{ width: "100%" }} />
              ) : (
                selectedTitle.name
              )}
              <Header.Subheader>
                {campaign.formHeading || "Request a Screening Quote"}
              </Header.Subheader>
            </Header>
            {/* {disney && (
              <div>
                <Image src="/images/youngwoman.png" />
                <Header
                  style={{ textAlign: "center" }}
                  content="Request a Screening Quote"
                />
              </div>
            )}
            {reagan && (
              <div>
                <Image src="/images/reagan.png" />
                <Header
                  style={{ textAlign: "center" }}
                  content={campaign.formHeading || "Request a Screening Quote"}
                />
              </div>
            )} */}

            {/* <Header
              as="h3"
              style={{
                textAlign: "center",
                marginBottom: "30px",
                color: formFocus ? campaign.labelColor : "",
              }}
            >
              {campaign.formHeading || "Request a Screening Quote"}
            </Header> */}
            <Form size="large">
              <Divider
                horizontal
                section
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              >
                <Header
                  as="h3"
                  style={{
                    color: formFocus ? campaign.labelColor : "",
                    fontWeight: 800,
                  }}
                >
                  Contact Information
                </Header>
              </Divider>
              <Form.Input
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.company}
                label="Group Name"
                placeholder="Group Name"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  resetErrors("company");
                }}
              />
              {/* <Form.Input
                  onFocus={() => setFormFocus(true)}
                  required
                  error={errorFields.industry}
                  label="Industry"
                  placeholder="Industry"
                  value={industry}
                  onChange={(e) => {
                    setIndustry(e.target.value);
                    resetErrors("industry");
                  }}
                /> */}
              <Form.Select
                search
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.industry}
                label="Industry"
                placeholder="Industry"
                value={industry}
                options={industries}
                onChange={(e, data) => {
                  setIndustry(data.value);
                  resetErrors("industry");
                }}
              />
              {industry === "Religious" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Icon name="level up" rotated="clockwise" />
                  <Form.Field style={{ width: "100%" }}>
                    <label>Denomination</label>
                    <Input
                      fluid
                      placeholder="Denomination"
                      value={industry2}
                      onChange={(e, data) => setIndustry2(e.target.value)}
                    />
                  </Form.Field>
                </div>
              )}
              {industry === "Religious" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Icon name="level up" rotated="clockwise" />
                  <Form.Field>
                    <label>501(c)(3) ?</label>
                    <Checkbox
                      radio
                      label="Yes"
                      checked={industry3 === "501(c)(3)"}
                      onChange={(e, data) => setIndustry3("501(c)(3)")}
                    />
                    <Checkbox
                      style={{ marginLeft: "10px" }}
                      radio
                      label="No"
                      checked={industry3 === ""}
                      onChange={(e, data) => setIndustry3("")}
                    />
                  </Form.Field>
                </div>
              )}
              <Form.Input
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.firstName}
                label="First Name"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  resetErrors("firstName");
                }}
              />
              <Form.Input
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.lastName}
                label="Last Name"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  resetErrors("lastName");
                }}
              />

              <Form.Input
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.address}
                label="Street Address"
                placeholder="Street Address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  resetErrors("address");
                }}
              />
              <Form.Input
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.city}
                label="City"
                placeholder="City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  resetErrors("city");
                }}
              />
              <Form.Select
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.state}
                search
                label="State"
                placeholder="State"
                value={state}
                options={states}
                onChange={handleSelectState}
              />
              <Form.Input
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.zipCode}
                label="Zip Code"
                placeholder="Zip Code"
                value={zipCode}
                onChange={(e) => {
                  setZipCode(e.target.value);
                  resetErrors("zipCode");
                }}
              />
              <Form.Input
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.phone}
                label="Phone"
                placeholder="Phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  resetErrors("phone");
                }}
              />
              <Form.Input
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.email}
                label="Email"
                placeholder="Email"
                value={email}
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  resetErrors("email");
                }}
              />
              <Divider
                horizontal
                section
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              >
                <Header
                  as="h3"
                  style={{ color: formFocus ? campaign.labelColor : "" }}
                >
                  Theater Selection
                </Header>
              </Divider>
              {campaign.marketsOnly && (
                <div>
                  <p>
                    Playdates are not yet available for this title. Please
                    select your preferred Theater Market, and we will reach out
                    to you to finalize your theater selection as soon as they
                    become available.
                  </p>
                  <Dropdown
                    fluid
                    selection
                    options={getMarkets()}
                    placeholder="Select your market"
                    onChange={onMarketChange}
                    value={market}
                  />
                </div>
              )}
              {!campaign.marketsOnly && (
                <Form.Field
                  style={{ textAlign: "center" }}
                  required
                  error={errorFields.theater}
                  onFocus={() => setFormFocus(true)}
                >
                  <span>
                    Search Theater By:{" "}
                    <Dropdown
                      disabled={theater !== null}
                      compact
                      selection
                      style={{ marginBottom: "5px" }}
                      placeholder="Search By..."
                      options={searchByOptions}
                      value={searchBy}
                      onChange={handleChangeSearchBy}
                    />
                  </span>
                  {searchBy === "zip" ? (
                    <p>
                      Enter a zip code, choose your search radius, and click
                      Search to find theaters in your area.
                    </p>
                  ) : (
                    <p>Enter the name of your theater and click Search.</p>
                  )}
                  <p>
                    <i>Location Subject to Approval</i>
                  </p>
                  <div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Dropdown
                        onFocus={() => setFormFocus(true)}
                        disabled={theater !== null}
                        icon={null}
                        error={errorFields.theater}
                        style={{ flex: 1 }}
                        fluid
                        placeholder={
                          searchBy === "zip"
                            ? "Enter Zip Code..."
                            : "Enter Theater Name..."
                        }
                        search
                        selection
                        searchQuery={searchValue}
                        onSearchChange={handleTheaterSearchValue}
                        openOnFocus={false}
                        open={searchResults && searchResults.length > 0}
                      >
                        <Dropdown.Menu>
                          <SearchResults />
                        </Dropdown.Menu>
                      </Dropdown>
                      {searchBy === "zip" && (
                        <Dropdown
                          disabled={theater !== null}
                          onFocus={() => setFormFocus(true)}
                          compact
                          selection
                          placeholder="Select Search Radius"
                          value={searchRadius}
                          options={radiusOptions}
                          onChange={handleChangeRadius}
                        />
                      )}
                    </div>
                    {isNoResults && (
                      <div style={{ margin: "10px" }}>
                        <Header
                          content="No Results to Display"
                          subheader="Try a different search, or expand your search radius."
                        />
                      </div>
                    )}
                    <Button
                      fluid
                      style={{
                        backgroundColor: campaign.labelColor
                          ? campaign.labelColor
                          : "",
                        color: "#FFFFFF",
                        marginTop: "5px",
                      }}
                      primary
                      content="Search"
                      loading={searching}
                      disabled={searching || theater !== null}
                      onClick={handleTheaterSearch}
                    />
                  </div>
                </Form.Field>
              )}
              {theater !== null && !campaign.marketsOnly && (
                <Form.Field inline>
                  <label>Selected Theater:</label>
                  <Label
                    style={{
                      backgroundColor: campaign.labelColor || "#0E6EB8",
                      color: "#FFFFFF",
                    }}
                    size="large"
                  >
                    {theater.name}{" "}
                    <Icon name="delete" onClick={handleClearTheaterSelection} />
                  </Label>
                </Form.Field>
              )}
              <Divider
                horizontal
                section
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              >
                <Header
                  as="h3"
                  style={{ color: formFocus ? campaign.labelColor : "" }}
                >
                  Event Date / Film Start Time
                </Header>
              </Divider>
              <Form.Field
                style={{ textAlign: "center" }}
                required
                error={errorFields.date}
                onFocus={() => setFormFocus(true)}
              >
                <p>Select your preferred date and time.</p>

                {!showTime && (
                  <DatePicker
                    placeholderText="Select a Date and Time"
                    timeIntervals={30}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    selected={date}
                    onChange={(date) => {
                      setDate(date);
                      resetErrors("date");
                      setShowTime(true);
                    }}
                    minDate={getMinDate(selectedTitle.startDate)}
                    maxDate={
                      selectedTitle.endDate
                        ? new Date(selectedTitle.endDate)
                        : null
                    }
                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                    maxTime={setHours(setMinutes(new Date(), 0), 23)}
                    excludeTimes={getExcludedTimes()}
                    excludeDates={getExcludeDates()}
                    inline
                  />
                )}
                {showTime && (
                  <DatePicker
                    placeholderText="Select a Date and Time"
                    showTimeSelect
                    timeIntervals={30}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    selected={date}
                    onChange={(date) => {
                      setDate(date);
                      resetErrors("date");
                      setShowTime(true);
                    }}
                    minDate={
                      selectedTitle.startDate
                        ? new Date(selectedTitle.startDate)
                        : new Date()
                    }
                    maxDate={
                      selectedTitle.endDate
                        ? new Date(selectedTitle.endDate)
                        : null
                    }
                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                    maxTime={setHours(setMinutes(new Date(), 0), 23)}
                    excludeTimes={getExcludedTimes()}
                    inline
                  />
                )}
                {date && (
                  <div>
                    <p>
                      <b>Selected Date: </b>
                      <Label
                        style={{
                          backgroundColor: campaign.labelColor || "#0E6EB8",
                          color: "#FFFFFF",
                        }}
                        size="large"
                        content={moment(date).format("LL")}
                      />
                    </p>
                    <p>
                      <b>Selected Time: </b>
                      {moment(date).format("LT") === "12:00 AM" ? (
                        "Please select a time..."
                      ) : (
                        <Label
                          style={{
                            backgroundColor: campaign.labelColor || "#0E6EB8",
                            color: "#FFFFFF",
                          }}
                          size="large"
                          content={moment(date).format("LT")}
                        />
                      )}
                    </p>
                  </div>
                )}
                {errorFields.date && (
                  <Message
                    visible
                    error
                    content="Select an available time slot..."
                  />
                )}
              </Form.Field>
              <Divider
                horizontal
                section
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              >
                <Header
                  as="h3"
                  style={{ color: formFocus ? campaign.labelColor : "" }}
                >
                  Group Size
                </Header>
              </Divider>
              <p>Enter the total number of attendees for your event.</p>
              <Form.Input
                onFocus={() => setFormFocus(true)}
                required
                error={errorFields.groupSize}
                label="Group Size"
                placeholder="Group Size"
                value={groupSize}
                onChange={(e) => {
                  handleChangeGroupSize(e);
                  resetErrors("groupSize");
                }}
                onBlur={handleGroupSizeCheck}
              />
              {campaign.enableComments && (
                <Form.TextArea
                  placeholder="Enter your questions or comments..."
                  label={`Questions / Comments (${comments.length} of 300 characters)`}
                  value={comments}
                  onChange={(e) => handleSetComments(e.target.value)}
                />
              )}
              <Form.Field>
                <Message
                  visible={groupSizeError}
                  error
                  header="Error"
                  content={groupSizeError}
                />
              </Form.Field>
              <Form.Field>
                <Message
                  visible={errorMessage}
                  error
                  header="Error"
                  content={errorMessage}
                />
              </Form.Field>
              <Divider section />
              {campaign.privacyLink && (
                <Form.Field style={{ textAlign: "center" }}>
                  <a
                    title={campaign.privacyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={campaign.privacyLink}
                  >
                    Privacy Policy
                  </a>
                </Form.Field>
              )}
              {!disney && (
                <Form.Checkbox
                  onFocus={() => setFormFocus(true)}
                  required
                  error={errorFields.checked}
                  label={campaign.terms || defaultTerms}
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                    resetErrors("checked");
                  }}
                />
              )}

              <Form.Button
                onFocus={() => setFormFocus(true)}
                loading={loading}
                disabled={loading}
                size="large"
                fluid
                primary
                style={{ backgroundColor: campaign.labelColor }}
                content="Submit"
                onClick={handleAddCustomer}
              />
              {imax && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{ cursor: "pointer" }}
                    href="#"
                    onClick={handleFooterClick}
                  >
                    <span>Privacy Center</span>
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    href="#"
                    onClick={handleFooterClick}
                  >
                    <span>Do Not Sell or Share My Information</span>
                  </a>
                </div>
              )}
            </Form>
          </div>
        ) : (
          <Header style={{ textAlign: "center" }} content="Success!">
            Request Received!
            <Header.Subheader>
              <AutoLinkText text={campaign.thankYou || defaultThankYou} />
            </Header.Subheader>
          </Header>
        )}
      </div>
      {disney && (
        <div
          style={{
            position: isMobile ? "" : "absolute",
            bottom: "0px",
            color: "#888888",
            zIndex: 1,
            width: "100%",
            // height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div>© 2024 Disney</div>
          <div style={{ margin: "10px" }}>|</div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://disneytermsofuse.com"
              style={{ color: "#888888" }}
            >
              Terms of Use
            </a>
          </div>
          <div style={{ margin: "10px" }}>|</div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://privacy.thewaltdisneycompany.com/en/"
              style={{ color: "#888888" }}
            >
              Privacy Policy
            </a>
          </div>
          <div style={{ margin: "10px" }}>|</div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/your-us-state-privacy-rights/"
              style={{ color: "#888888" }}
            >
              Your US State Privacy Rights
            </a>
          </div>
          <div style={{ margin: "10px" }}>|</div>
          <div>
            <a
              style={{
                color: "#888888",
                border: "0px",
                padding: "0px",
                fontSize: "14px",
              }}
              href="#"
              id="ot-sdk-btn"
              className="ot-sdk-show-settings"
            >
              Do Not Sell or Share My Personal Information
            </a>
          </div>
          <div style={{ margin: "10px" }}>|</div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://privacy.thewaltdisneycompany.com/en/for-parents/childrens-online-privacy-policy/"
              style={{ color: "#888888" }}
            >
              Children's Online Privacy Policy
            </a>
          </div>
          <div style={{ margin: "10px" }}>|</div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://preferences-mgr.truste.com/?type=disneycolor&affiliateId=115"
              style={{ color: "#888888" }}
            >
              Interest-Based Ads
            </a>
          </div>
          <Image
            style={{
              width: "50px",
              marginLeft: "50px",
              position: "fixed",
              bottom: "5px",
              right: "5px",
            }}
            src="/images/youngwoman-rating.png"
          />
        </div>
      )}
      {disney && <div id="ot-sdk-cookie-policy"></div>}
    </div>
  );
};
export default IntakeForm;
