import { Button, Modal, Image } from "semantic-ui-react";
// import { Anchorme } from "react-anchorme";
import AutoLinkText from "react-autolink-text2";
const GroupError = (props) => {
  const { amcError, regalError, cinemarkError, handleDismissError, campaign } =
    props;
  const defaultAmc = `For AMC theaters with parties of ${
    campaign.amcThreshold || 30
  } or less, please purchase tickets directly from AMC: https://www.amctheatres.com/group-sales`;
  const defaultRegal = `For Regal theaters with parties of ${
    campaign.regalThreshold || 30
  } or less, please purchase tickets directly from Regal: https://www.regmovies.com/groups-and-events`;
  const defaultCinemark = `For Cinemark theaters with parties of ${
    campaign.cinemarkThreshold || 30
  } or less, please purchase tickets directly from Cinemark: https://www.cinemark.com/private-events`;
  return (
    <Modal open size="tiny">
      <Modal.Content>
        {amcError && (
          <div>
            <div style={{ padding: 20 }}>
              <Image src="/images/amc-logo.png" width="500px" />
            </div>
            <AutoLinkText
              target="_blank"
              rel="noreferrer noopener"
              text={campaign.amcText || defaultAmc}
            />
          </div>
        )}
        {regalError && (
          <div>
            <div style={{ padding: 20 }}>
              <Image src="/images/regal-logo.png" width="500px" />
            </div>
            <AutoLinkText
              target="_blank"
              rel="noreferrer noopener"
              text={campaign.regalText || defaultRegal}
            />
          </div>
        )}
        {cinemarkError && (
          <div>
            <div style={{ padding: 20 }}>
              <Image src="/images/cinemark-logo.png" width="500px" />
            </div>
            <AutoLinkText
              target="_blank"
              rel="noreferrer noopener"
              text={campaign.cinemarkText || defaultCinemark}
            />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Dismiss" onClick={handleDismissError} />
      </Modal.Actions>
    </Modal>
  );
};
export default GroupError;
