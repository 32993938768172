import { gql } from "@apollo/client";

// queries
export const AGENTS = gql`
  query agents {
    agents {
      agentId
      email
      fullName
      phone
      role
      active
      studioId
      circuitId
      themeColor
      backgroundColor
      logoPath
      studioName
      circuitName
      pendingApproval
      lastLogin
      deactivated
      address
      city
      state
      zipCode
      apikey
      titleId
      readOnly
      disableExport
      enableNotifications
    }
  }
`;

export const CUSTOMERS = gql`
  query customers($archived: Boolean, $titleId: String) {
    customers(archived: $archived, titleId: $titleId) {
      company
      firstName
      lastName
      email
      phone
      address
      city
      state
      zipCode
      groupSize
      date
      status
      leadSource
      customerId
      theaterId
      studioId
      circuitId
      titleId
      created
      priority
      notes
      dollarValue
      auditorium
      seats
      ticketPrice
      industry
      campaignId
      quote
      lastUpdateAgentName
      lastUpdateAgentId
      lastUpdateTimestamp
      missingOwner
      hasNotes
      market
      comments
      approvalTime
      pingCount
      lastPing
      reference
    }
  }
`;

export const CUSTOMER_REPORTS = gql`
  query customerReports($archived: Boolean) {
    customerReports(archived: $archived) {
      company
      firstName
      lastName
      email
      phone
      address
      city
      state
      zipCode
      groupSize
      date
      status
      leadSource
      customerId
      theaterId
      studioId
      circuitId
      titleId
      created
      priority
      notes
      dollarValue
      auditorium
      seats
      ticketPrice
      industry
      campaignId
      quote
      lastUpdateAgentName
      lastUpdateAgentId
      lastUpdateTimestamp
      market
    }
  }
`;

export const QUOTES = gql`
  query quotes {
    quotes {
      company
      groupSize
      date
      status
      customerId
      theaterId
      studioId
      circuitId
      titleId
      created
      priority
      seats
      ticketPrice
      industry
      titleName
      studioName
      theaterName
      theaterZip
      quote
    }
  }
`;

export const THEATERS = gql`
  query theaters(
    $zip: String
    $radius: String
    $name: String
    $campaignId: String
    $circuitId: String
  ) {
    theaters(
      zip: $zip
      radius: $radius
      name: $name
      campaignId: $campaignId
      circuitId: $circuitId
    ) {
      theaterId
      overallRank
      name
      nameLocale
      screens
      circuitAbbrv
      circuit
      metro
      metroRank
      city
      state
      zipCode
      rtkTheaterId
      branch
      zone
      area
      circuitId
      cinemaId
      campaigns
      alternateId
    }
  }
`;

export const STUDIOS = gql`
  query studios($studioId: String) {
    studios(studioId: $studioId) {
      studioId
      name
      logoPath
      artworkLink
    }
  }
`;

export const CIRCUITS = gql`
  query circuits {
    circuits {
      circuitId
      circuit
    }
  }
`;

export const MESSAGES = gql`
  query messages($customerId: String) {
    messages(customerId: $customerId) {
      messageId
      customerId
      timestamp
      message
      agentId
      agentName
      internal
      edited
      deleted
    }
  }
`;

export const MAIL_LOG = gql`
  query mailLog {
    mailLog {
      mailLogId
      from
      to
      replyTo
      subject
      html
      createdAt
    }
  }
`;

export const TITLES = gql`
  query titles($titleId: String) {
    titles(titleId: $titleId) {
      titleId
      name
      releaseDate
      startDate
      rating
      studioId
      createdDate
      endDate
      amcText
      amcThreshold
      regalText
      regalThreshold
      cinemarkText
      cinemarkThreshold
      defaultThreshold
      archived
    }
  }
`;

export const CAMPAIGNS = gql`
  query campaigns($titleId: String, $campaignId: String, $studioId: String) {
    campaigns(titleId: $titleId, campaignId: $campaignId, studioId: $studioId) {
      titleId
      studioId
      campaignId
      createdDate
      terms
      thankYou
      artworkPath
      artworkLink
      studioName
      layout
      gateway
      gatewayDecline
      labelColor
      startDate
      endDate
      footerText1
      footerText2
      footerText3
      limitedTheaters
      formHeading
      privacyLink
      contactLink
      disableEmail
      emailText
      emailSubject
      groupPolicy
      expiredText
      titleName
      marketsOnly
      markets
      trailerUrl
      logoPath
      logoLink
      enableComments
      defaultRadius
      preventNearSelection
      nearSelectionDays
    }
  }
`;

export const GET_UPLOAD_LINK = gql`
  query getUploadLink($file: String) {
    getUploadLink(file: $file) {
      link
    }
  }
`;

export const GET_DOWNLOAD_LINK = gql`
  query getDownloadLink($file: String) {
    getDownloadLink(file: $file) {
      link
    }
  }
`;

// mutations

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      status
      token
    }
  }
`;

export const ADD_CUSTOMER = gql`
  mutation addCustomer(
    $firstName: String
    $lastName: String
    $company: String
    $email: String
    $phone: String
    $address: String
    $city: String
    $state: String
    $zipCode: String
    $groupSize: String
    $date: String
    $theaterId: String
    $studioId: String
    $circuitId: String
    $titleId: String
    $industry: String
    $campaignId: String
    $agentId: String
    $status: String
    $market: String
    $priority: String
    $comments: String
  ) {
    addCustomer(
      customerInfo: {
        firstName: $firstName
        lastName: $lastName
        company: $company
        email: $email
        phone: $phone
        address: $address
        city: $city
        state: $state
        zipCode: $zipCode
        groupSize: $groupSize
        date: $date
        theaterId: $theaterId
        studioId: $studioId
        circuitId: $circuitId
        titleId: $titleId
        industry: $industry
        campaignId: $campaignId
        agentId: $agentId
        status: $status
        market: $market
        priority: $priority
        comments: $comments
      }
    ) {
      status
      customerId
    }
  }
`;

export const ADD_CUSTOMERS = gql`
  mutation addCustomers($customerInfo: [CustomerInfo]) {
    addCustomers(customerInfo: $customerInfo) {
      status
    }
  }
`;

export const UPDATE_CUSTOMERS = gql`
  mutation updateCustomers($customerInfo: [UpdateCustomerInfo]) {
    updateCustomers(customerInfo: $customerInfo) {
      status
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $customerId: String!
    $status: String
    $priority: String
    $firstName: String
    $lastName: String
    $company: String
    $email: String
    $phone: String
    $address: String
    $city: String
    $state: String
    $zipCode: String
    $groupSize: String
    $date: String
    $theaterId: String
    $studioId: String
    $circuitId: String
    $titleId: String
    $notes: String
    $dollarValue: String
    $auditorium: String
    $seats: String
    $ticketPrice: String
    $industry: String
    $quote: String
    $market: String
    $comments: String
  ) {
    updateCustomer(
      customerInfo: {
        customerId: $customerId
        status: $status
        priority: $priority
        firstName: $firstName
        lastName: $lastName
        company: $company
        email: $email
        phone: $phone
        address: $address
        city: $city
        state: $state
        zipCode: $zipCode
        groupSize: $groupSize
        date: $date
        theaterId: $theaterId
        studioId: $studioId
        circuitId: $circuitId
        titleId: $titleId
        notes: $notes
        dollarValue: $dollarValue
        auditorium: $auditorium
        seats: $seats
        ticketPrice: $ticketPrice
        industry: $industry
        quote: $quote
        market: $market
        comments: $comments
      }
    ) {
      status
      customerId
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($customerId: String!) {
    deleteCustomer(customerId: $customerId) {
      status
      message
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp(
    $fullName: String
    $email: String
    $phone: String
    $role: String
    $companyName: String
    $address: String
    $city: String
    $state: String
    $zipCode: String
  ) {
    signUp(
      agentInfo: {
        fullName: $fullName
        email: $email
        phone: $phone
        role: $role
        companyName: $companyName
        address: $address
        city: $city
        state: $state
        zipCode: $zipCode
      }
    ) {
      status
      agentId
    }
  }
`;

export const SEND_ACTIVATION_MANUALLY = gql`
  mutation sendActivationManually($email: String) {
    sendActivationManually(agentInfo: { email: $email }) {
      status
      agentId
    }
  }
`;

export const CREATE_AGENT = gql`
  mutation createAgent(
    $email: String
    $role: String
    $fullName: String
    $studioId: String
    $circuitId: String
    $themeColor: String
    $backgroundColor: String
    $logoPath: String
    $phone: String
    $deactivated: Boolean
    $address: String
    $city: String
    $state: String
    $zipCode: String
    $titleId: String
    $readOnly: Boolean
    $disableExport: Boolean
    $enableNotifications: Boolean
  ) {
    createAgent(
      agentInfo: {
        email: $email
        role: $role
        fullName: $fullName
        studioId: $studioId
        circuitId: $circuitId
        themeColor: $themeColor
        backgroundColor: $backgroundColor
        logoPath: $logoPath
        phone: $phone
        deactivated: $deactivated
        address: $address
        city: $city
        state: $state
        zipCode: $zipCode
        titleId: $titleId
        readOnly: $readOnly
        disableExport: $disableExport
        enableNotifications: $enableNotifications
      }
    ) {
      status
      agentId
      message
    }
  }
`;

export const UPDATE_AGENT = gql`
  mutation updateAgent(
    $agentId: String!
    $email: String
    $role: String
    $fullName: String
    $active: Boolean
    $studioId: String
    $circuitId: String
    $themeColor: String
    $backgroundColor: String
    $logoPath: String
    $phone: String
    $deactivated: Boolean
    $address: String
    $city: String
    $state: String
    $zipCode: String
    $titleId: String
    $readOnly: Boolean
    $disableExport: Boolean
    $enableNotifications: Boolean
  ) {
    updateAgent(
      agentInfo: {
        agentId: $agentId
        email: $email
        role: $role
        fullName: $fullName
        active: $active
        studioId: $studioId
        circuitId: $circuitId
        themeColor: $themeColor
        backgroundColor: $backgroundColor
        logoPath: $logoPath
        phone: $phone
        deactivated: $deactivated
        address: $address
        city: $city
        state: $state
        zipCode: $zipCode
        titleId: $titleId
        readOnly: $readOnly
        disableExport: $disableExport
        enableNotifications: $enableNotifications
      }
    ) {
      status
      token
      message
    }
  }
`;

export const DELETE_AGENT = gql`
  mutation deleteAgent($agentId: String!, $deleted: Boolean) {
    deleteAgent(agentInfo: { agentId: $agentId, deleted: $deleted }) {
      status
      message
    }
  }
`;

export const AGENT_LOGIN_WITH_TOKEN = gql`
  mutation agentLoginWithToken($token: String!, $agentId: String!) {
    agentLoginWithToken(token: $token, agentId: $agentId) {
      status
      token
    }
  }
`;

export const ACTIVATE_AGENT_ACCOUNT = gql`
  mutation activateAgentAccount(
    $token: String!
    $agentId: String!
    $password: String!
  ) {
    activateAgentAccount(
      token: $token
      agentId: $agentId
      password: $password
    ) {
      status
      token
    }
  }
`;

export const SEND_RESET_TOKEN = gql`
  mutation sendResetToken($email: String!) {
    sendResetToken(email: $email) {
      status
      message
      result
    }
  }
`;

export const ADD_STUDIO = gql`
  mutation addStudio($name: String, $logoPath: String) {
    addStudio(studioInfo: { name: $name, logoPath: $logoPath }) {
      status
      studioId
    }
  }
`;

export const UPDATE_STUDIO = gql`
  mutation updateStudio($name: String, $studioId: String!, $logoPath: String) {
    updateStudio(
      studioInfo: { name: $name, studioId: $studioId, logoPath: $logoPath }
    ) {
      status
      studioId
    }
  }
`;

export const ADD_CIRCUIT = gql`
  mutation addCircuit($circuit: String) {
    addCircuit(circuitInfo: { circuit: $circuit }) {
      status
    }
  }
`;

export const UPDATE_CIRCUIT = gql`
  mutation updateCircuit($circuit: String, $circuitId: String!) {
    updateCircuit(circuitInfo: { circuit: $circuit, circuitId: $circuitId }) {
      status
    }
  }
`;

export const PING_EXHIBITOR = gql`
  mutation pingExhibitor($customerId: String!, $pingMessage: String!) {
    pingExhibitor(customerId: $customerId, pingMessage: $pingMessage) {
      status
      message
    }
  }
`;

export const ADD_MESSAGE = gql`
  mutation addMessage(
    $customerId: String
    $agentId: String
    $message: String
    $internal: Boolean
  ) {
    addMessage(
      messageInfo: {
        customerId: $customerId
        agentId: $agentId
        message: $message
        internal: $internal
      }
    ) {
      status
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation updateMessage(
    $messageId: String!
    $customerId: String
    $agentId: String
    $message: String
    $internal: Boolean
    $edited: Boolean
  ) {
    updateMessage(
      messageInfo: {
        messageId: $messageId
        customerId: $customerId
        agentId: $agentId
        message: $message
        internal: $internal
        edited: $edited
      }
    ) {
      status
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation deleteMessage(
    $messageId: String!
    $agentId: String
    $deleted: Boolean
  ) {
    deleteMessage(
      messageInfo: {
        messageId: $messageId
        agentId: $agentId
        deleted: $deleted
      }
    ) {
      status
    }
  }
`;

export const DELETE_CIRCUIT = gql`
  mutation deleteCircuit($circuitId: String!) {
    deleteCircuit(circuitInfo: { circuitId: $circuitId }) {
      status
      message
    }
  }
`;

export const TAG_THEATERS = gql`
  mutation tagTheaters($theaterInfo: [TagTheaterInfo]) {
    tagTheaters(theaterInfo: $theaterInfo) {
      status
      tagResult
    }
  }
`;

export const UNTAG_THEATERS = gql`
  mutation untagTheaters($circuitId: String, $campaignId: String) {
    untagTheaters(
      theaterInfo: { circuitId: $circuitId, campaignId: $campaignId }
    ) {
      status
      message
    }
  }
`;

export const ADD_THEATER = gql`
  mutation addTheater(
    $circuit: String
    $circuitId: String
    $rtkTheaterId: String
    $name: String
    $city: String
    $state: String
    $zipCode: String
    $campaigns: String
    $alternateId: String
  ) {
    addTheater(
      theaterInfo: {
        circuit: $circuit
        circuitId: $circuitId
        rtkTheaterId: $rtkTheaterId
        name: $name
        city: $city
        state: $state
        zipCode: $zipCode
        campaigns: $campaigns
        alternateId: $alternateId
      }
    ) {
      status
    }
  }
`;

export const UPDATE_THEATER = gql`
  mutation updateTheater(
    $circuit: String
    $circuitId: String
    $theaterId: String!
    $rtkTheaterId: String
    $name: String
    $city: String
    $state: String
    $zipCode: String
    $campaigns: String
    $alternateId: String
  ) {
    updateTheater(
      theaterInfo: {
        circuit: $circuit
        circuitId: $circuitId
        theaterId: $theaterId
        rtkTheaterId: $rtkTheaterId
        name: $name
        city: $city
        state: $state
        zipCode: $zipCode
        campaigns: $campaigns
        alternateId: $alternateId
      }
    ) {
      status
    }
  }
`;

export const ADD_TITLE = gql`
  mutation addTitle(
    $name: String
    $releaseDate: String
    $startDate: String
    $rating: String
    $createdDate: String
    $studioId: String
    $endDate: String
    $amcText: String
    $amcThreshold: String
    $regalText: String
    $regalThreshold: String
    $cinemarkText: String
    $cinemarkThreshold: String
    $defaultThreshold: String
  ) {
    addTitle(
      titleInfo: {
        name: $name
        releaseDate: $releaseDate
        startDate: $startDate
        rating: $rating
        createdDate: $createdDate
        studioId: $studioId
        endDate: $endDate
        amcText: $amcText
        amcThreshold: $amcThreshold
        regalText: $regalText
        regalThreshold: $regalThreshold
        cinemarkText: $cinemarkText
        cinemarkThreshold: $cinemarkThreshold
        defaultThreshold: $defaultThreshold
      }
    ) {
      status
    }
  }
`;

export const UPDATE_TITLE = gql`
  mutation updateTitle(
    $titleId: String
    $name: String
    $releaseDate: String
    $startDate: String
    $rating: String
    $studioId: String
    $endDate: String
    $amcText: String
    $amcThreshold: String
    $regalText: String
    $regalThreshold: String
    $cinemarkText: String
    $cinemarkThreshold: String
    $defaultThreshold: String
  ) {
    updateTitle(
      titleInfo: {
        titleId: $titleId
        name: $name
        releaseDate: $releaseDate
        startDate: $startDate
        rating: $rating
        studioId: $studioId
        endDate: $endDate
        amcText: $amcText
        amcThreshold: $amcThreshold
        regalText: $regalText
        regalThreshold: $regalThreshold
        cinemarkText: $cinemarkText
        cinemarkThreshold: $cinemarkThreshold
        defaultThreshold: $defaultThreshold
      }
    ) {
      status
    }
  }
`;

export const ADD_CAMPAIGN = gql`
  mutation addCampaign(
    $titleId: String
    $artworkPath: String
    $studioId: String
    $terms: String
    $thankYou: String
    $layout: String
    $gateway: String
    $gatewayDecline: String
    $labelColor: String
    $footerText1: String
    $footerText2: String
    $footerText3: String
    $limitedTheaters: Boolean
    $formHeading: String
    $privacyLink: String
    $contactLink: String
    $disableEmail: Boolean
    $emailText: String
    $groupPolicy: String
    $expiredText: String
    $marketsOnly: Boolean
    $markets: String
    $trailerUrl: String
    $logoPath: String
    $enableComments: Boolean
    $emailSubject: String
    $defaultRadius: String
    $preventNearSelection: Boolean
    $nearSelectionDays: String
  ) {
    addCampaign(
      campaignInfo: {
        titleId: $titleId
        artworkPath: $artworkPath
        studioId: $studioId
        terms: $terms
        thankYou: $thankYou
        layout: $layout
        gateway: $gateway
        gatewayDecline: $gatewayDecline
        labelColor: $labelColor
        footerText1: $footerText1
        footerText2: $footerText2
        footerText3: $footerText3
        limitedTheaters: $limitedTheaters
        formHeading: $formHeading
        privacyLink: $privacyLink
        contactLink: $contactLink
        disableEmail: $disableEmail
        emailText: $emailText
        groupPolicy: $groupPolicy
        expiredText: $expiredText
        marketsOnly: $marketsOnly
        markets: $markets
        trailerUrl: $trailerUrl
        logoPath: $logoPath
        enableComments: $enableComments
        emailSubject: $emailSubject
        defaultRadius: $defaultRadius
        preventNearSelection: $preventNearSelection
        nearSelectionDays: $nearSelectionDays
      }
    ) {
      status
      campaignId
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign(
    $titleId: String
    $artworkPath: String
    $campaignId: String!
    $terms: String
    $thankYou: String
    $layout: String
    $gateway: String
    $gatewayDecline: String
    $labelColor: String
    $footerText1: String
    $footerText2: String
    $footerText3: String
    $limitedTheaters: Boolean
    $formHeading: String
    $privacyLink: String
    $contactLink: String
    $disableEmail: Boolean
    $emailText: String
    $groupPolicy: String
    $expiredText: String
    $marketsOnly: Boolean
    $markets: String
    $trailerUrl: String
    $logoPath: String
    $enableComments: Boolean
    $emailSubject: String
    $defaultRadius: String
    $preventNearSelection: Boolean
    $nearSelectionDays: String
  ) {
    updateCampaign(
      campaignInfo: {
        titleId: $titleId
        artworkPath: $artworkPath
        campaignId: $campaignId
        terms: $terms
        thankYou: $thankYou
        layout: $layout
        gateway: $gateway
        gatewayDecline: $gatewayDecline
        labelColor: $labelColor
        footerText1: $footerText1
        footerText2: $footerText2
        footerText3: $footerText3
        limitedTheaters: $limitedTheaters
        formHeading: $formHeading
        privacyLink: $privacyLink
        contactLink: $contactLink
        disableEmail: $disableEmail
        emailText: $emailText
        groupPolicy: $groupPolicy
        expiredText: $expiredText
        marketsOnly: $marketsOnly
        markets: $markets
        trailerUrl: $trailerUrl
        logoPath: $logoPath
        enableComments: $enableComments
        emailSubject: $emailSubject
        defaultRadius: $defaultRadius
        preventNearSelection: $preventNearSelection
        nearSelectionDays: $nearSelectionDays
      }
    ) {
      status
      campaignId
    }
  }
`;

export const CREATE_API_KEY = gql`
  mutation createAPIKey($agentId: String!) {
    createAPIKey(agentInfo: { agentId: $agentId }) {
      status
    }
  }
`;

export const REVOKE_API_KEY = gql`
  mutation revokeAPIKey($agentId: String!) {
    revokeAPIKey(agentInfo: { agentId: $agentId }) {
      status
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation sendEmail(
    $emailText: String
    $emailSubject: String
    $recipientList: String
    $emailAttachment: String
    $attachmentName: String
    $fromMe: Boolean
  ) {
    sendEmail(
      sendInfo: {
        emailText: $emailText
        emailSubject: $emailSubject
        recipientList: $recipientList
        emailAttachment: $emailAttachment
        attachmentName: $attachmentName
        fromMe: $fromMe
      }
    ) {
      status
      message
    }
  }
`;
