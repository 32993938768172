import { useState, useEffect, useRef } from "react";
import {
  Header,
  Modal,
  Button,
  Image,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import Background from "./Background";
import Login from "./Login";
import Agents from "./Agents/Agents";
import MenuItems from "./Menu/MenuItems";
import Settings from "./Menu/Settings";
import {
  LOGIN,
  ACTIVATE_AGENT_ACCOUNT,
  SEND_RESET_TOKEN,
  AGENT_LOGIN_WITH_TOKEN,
  GET_DOWNLOAD_LINK,
  CUSTOMERS,
  QUOTES,
} from "./graphQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { AuthContext } from "./AuthContext";
import SetPassword from "./SetPassword";
import ForgotPassword from "./ForgotPassword";
import Home from "./NewHome/Home";
import Customers from "./Customers/Customers";
import Studios from "./Studios/Studios";
import Theaters from "./Theaters/Theaters";
import Circuits from "./Circuits/Circuits";
import Titles from "./Titles/Titles";
import Campaigns from "./Campaigns/Campaigns";
import Quotes from "./Quotes/Quotes";
import Help from "./Menu/Help";
import Mail from "./Mail/Mail";

const Dashboard = () => {
  const [authenticated, setAuth] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeMenuItem, setActiveMenuItem] = useState("home");
  const [loading, setLoading] = useState(false);
  const [loginContext, setLoginContext] = useState({
    agentId: "",
    authenticated: false,
    email: "",
    exp: null,
    role: "",
    studioId: "",
    circuitId: "",
    fullName: "",
    logoPath: "",
    themeColor: "",
    backgroundColor: "",
    readOnly: false,
    disableExport: false,
    enableNotifications: false,
  });
  const [resetToken, setResetToken] = useState("");
  const [resetId, setResetId] = useState("");
  const [isReset, setIsReset] = useState(false);
  const [isForgot, setIsForgot] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [userName, setUserName] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isExpired, setExpired] = useState(false);
  const [isSettings, setSettings] = useState(false);
  const [logo, setLogo] = useState("");
  const [logoReady, setLogoReady] = useState(false);
  const [newCustomersSize, setNewCustomerSize] = useState("");
  const [newQuoteSize, setNewQuoteSize] = useState("");
  const [isHelp, setHelp] = useState(false);
  const [defaultState, setDefault] = useState(true);

  const clearTimerRef = useRef();

  useEffect(() => {
    checkForExpiration();
    if (loginContext.authenticated) {
      handleFetchCustomers();
      if (loginContext.role === "Exhibitor") {
        quotes();
      }
    }
  }, [loginContext]);

  useEffect(() => {
    const setupDefaults = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          handleCreateLoginContext(token);
        } else {
          setDefault(false);
        }
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const resetToken = urlParams.get("token");
        const resetId = urlParams.get("u");
        const resetSkip = urlParams.get("skipReset");
        const userName = urlParams.get("userName");
        if (userName) setUserName(userName);
        setResetId(resetId);
        setResetToken(resetToken);
        window.history.pushState({}, document.title, "/");
        if (resetId && resetToken && !resetSkip) {
          setIsReset(true);
        } else if (resetId && resetToken && resetSkip) {
          agentLoginWithToken({
            variables: { token: resetToken, agentId: resetId },
          });
        }
      } catch (error) {
        console.error("Error occurred during setupDefaults:", error);
      }
    };
    setupDefaults();
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    clearInterval(clearTimerRef.current);
    clearTimerRef.current = setInterval(() => {
      checkForExpiration();
    }, 30000);
  }, [loginContext]);

  const checkForExpiration = () => {
    // console.log(loginContext);
    // console.log(loginContext.exp * 1000);
    // console.log(new Date(loginContext.exp * 1000) - new Date());
    const timeLeft = new Date(loginContext.exp * 1000) - new Date();
    if (loginContext.exp && timeLeft < 0) {
      console.log("TIME EXPIRED");
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      return true;
    }
  };

  const handleResizeEvent = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleFetchQuotes = () => {
    quotes();
  };

  const [quotes] = useLazyQuery(QUOTES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleQuotes_Result(result),
    onError: (error) => handleQuotes_Error(error),
  });

  const handleQuotes_Result = (result) => {
    if (result.quotes && result.quotes.length) {
      setNewQuoteSize(result.quotes.length);
    } else {
      setNewQuoteSize("");
    }
  };
  const handleQuotes_Error = (error) => {
    console.log(error);
  };

  const handleFetchCustomers = (archived, titleId) => {
    customers({ variables: { archived, titleId } });
  };

  const [customers] = useLazyQuery(CUSTOMERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCustomers_Result(result),
    onError: (error) => handleCustomers_Error(error),
  });
  const handleCustomers_Result = (result) => {
    console.log(result);
    if (result.customers) {
      const isExhibitor = loginContext.role === "Exhibitor";
      const newStatus = isExhibitor ? "Approved" : "New";
      const newOnly = result.customers.filter((x) => x.status === newStatus);
      setNewCustomerSize(newOnly.length);
    }
  };
  const handleCustomers_Error = (error) => {
    console.log(error);
  };

  const [activateAgentAccount] = useMutation(ACTIVATE_AGENT_ACCOUNT, {
    onCompleted: (result) => handleActivate_Result(result),
    onError: (error) => handleActivate_Error(error),
  });

  const [sendResetToken] = useMutation(SEND_RESET_TOKEN, {
    onCompleted: (result) => handleSendReset_Result(result),
    onError: (error) => handleSendReset_Error(error),
  });

  const [login] = useMutation(LOGIN, {
    onCompleted: (result) => handleLogin_Result(result),
    onError: (error) => handleLogin_Error(error),
  });

  const [agentLoginWithToken] = useMutation(AGENT_LOGIN_WITH_TOKEN, {
    onCompleted: (result) => handleLoginWithToken_Result(result),
    onError: (error) => handleLoginWithToken_Error(error),
  });

  const handleLoginWithToken_Result = (result) => {
    console.log(result);
    if (
      result &&
      result.agentLoginWithToken &&
      result.agentLoginWithToken.token
    ) {
      localStorage.setItem("token", result.agentLoginWithToken.token);
      handleCreateLoginContext(result.agentLoginWithToken.token);
      setLoading(false);
    }
  };

  const handleLoginWithToken_Error = (error) => {
    console.log(error);
  };

  const handleLogin = (email, password) => {
    setErrorMessage("");
    setLoading(true);
    if (email && password) {
      setTimeout(() => {
        login({
          variables: {
            email: email.trim(),
            password: password.trim(),
          },
        });
      }, 1000);
    } else {
      alert("Please fill out all required fields.");
      setLoading(false);
    }
  };

  const handleLogin_Result = (result) => {
    if (result && result.login && result.login.token) {
      localStorage.setItem("token", result.login.token);
      handleCreateLoginContext(result.login.token);
      setLoading(false);
    }
  };

  const handleLogin_Error = (error) => {
    console.log(error.message);
    setErrorMessage(error.message);
    setLoading(false);
  };

  const handleCreateLoginContext = async (token) => {
    const decoded = jwtDecode(token);
    console.log(decoded);
    setLoginContext({
      agentId: decoded.agentId,
      authenticated: decoded.authenticated,
      email: decoded.email,
      exp: decoded.exp,
      role: decoded.role,
      studioId: decoded.studioId,
      circuitId: decoded.circuitId,
      fullName: decoded.fullName,
      logoPath: decoded.logoPath,
      themeColor: decoded.themeColor,
      backgroundColor: decoded.backgroundColor,
      readOnly: decoded.readOnly,
      disableExport: decoded.disableExport,
      enableNotifications: decoded.enableNotifications,
    });
    const stillValid = await checkForExpiration();
    if (stillValid) {
      setAuth(decoded.authenticated);
      setDefault(false);
    }
    setAuth(decoded.authenticated);
    // setup theme
    if (decoded.logoPath) {
      getDownloadLink({ variables: { file: decoded.logoPath } });
    }
  };

  const [getDownloadLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetDownloadLink_Result(result),
    onError: (error) => handleGetDownloadLink_Error(error),
  });

  const handleGetDownloadLink_Result = (result) => {
    console.log(result);
    if (result && result.getDownloadLink.link) {
      setLogo(result.getDownloadLink.link);
    }
  };

  const handleGetDownloadLink_Error = (error) => {
    console.log(error);
  };

  const handleChangeMenu = (menuItem) => {
    if (menuItem === "logout") {
      console.log("LOGOUT TRIGGERED");
      localStorage.removeItem("token");
      window.location.reload();
      // setAuth(false);
      // setActiveMenuItem("home");
      return;
    }
    setActiveMenuItem(menuItem);
  };

  const handleIntake = (studioId) => {
    window.open(`/intake/?studio=${studioId}`, "_blank");
  };

  const handleActivateAgent = (password) => {
    setLoading(true);
    setTimeout(() => {
      activateAgentAccount({
        variables: {
          agentId: resetId,
          token: resetToken,
          password,
        },
      });
    }, 1000);
  };

  const handleActivate_Result = (result) => {
    console.log(result);
    if (result.activateAgentAccount) {
      if (result.activateAgentAccount.status === "succeeded") {
        localStorage.setItem("token", result.activateAgentAccount.token);
        handleCreateLoginContext(result.activateAgentAccount.token);
        setLoading(false);
        setIsReset(false);
      }
      if (result.activateAgentAccount.status === "failed") {
        setLoading(false);
        setErrorMessage(
          "Error: Failed to activate. The link/token you are using is expired or no longer valid."
        );
      }
    }
  };

  const handleActivate_Error = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
    console.log(error);
  };

  const handleForgotPassword = () => {
    setIsForgot(true);
  };

  const handleSendPasswordReset = (email) => {
    if (email) {
      setLoading(true);
      setErrorMessage("");
      setTimeout(() => {
        // password reset mutation
        sendResetToken({
          variables: {
            email: email.trim(),
          },
        });
      }, 1000);
    }
  };

  const handleSendReset_Result = (result) => {
    console.log(result);
    if (result.sendResetToken) {
      if (result.sendResetToken.status === "succeeded") {
        setLoading(false);
        setResetSuccess(true);
        setTimeout(() => {
          setIsForgot(false);
          setResetSuccess(false);
        }, 3000);
      }
      if (result.sendResetToken.status === "failed") {
        setLoading(false);
        setErrorMessage(result.sendResetToken.message);
      }
    }
  };

  const handleSendReset_Error = (error) => {
    console.log(error);
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSettings = () => {
    setSettings(!isSettings);
  };

  const handleHelp = () => {
    setHelp(!isHelp);
  };

  return (
    <div>
      {isExpired && (
        <Modal open size="tiny" style={{ width: "200px" }}>
          <Modal.Content>
            <Header style={{ textAlign: "center" }}>
              Session Expired.
              <Header.Subheader>Click to login again.</Header.Subheader>
            </Header>
            <Button
              fluid
              content="Login"
              onClick={() => window.location.reload()}
            />
          </Modal.Content>
        </Modal>
      )}
      {defaultState && (
        <Dimmer active page>
          <Loader active content="Loading the Tower..." size="large" />
        </Dimmer>
      )}
      {!authenticated && !defaultState && (
        <Background>
          {isReset && (
            <SetPassword
              handleActivateAgent={handleActivateAgent}
              loading={loading}
              errorMessage={errorMessage}
              userName={userName}
            />
          )}
          {!isReset && !isForgot && (
            <Login
              handleLogin={handleLogin}
              handleForgotPassword={handleForgotPassword}
              errorMessage={errorMessage}
              loading={loading}
            />
          )}
          {isForgot && (
            <ForgotPassword
              handleSendPasswordReset={handleSendPasswordReset}
              loading={loading}
              errorMessage={errorMessage}
              resetSuccess={resetSuccess}
            />
          )}
        </Background>
      )}
      <AuthContext.Provider value={{ loginContext }}>
        {authenticated && !defaultState && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: loginContext.backgroundColor || "#CCCCCC",
              width: "100vw",
              height: "100vh",
            }}
          >
            <MenuItems
              newQuoteSize={newQuoteSize}
              newCustomersSize={newCustomersSize}
              windowWidth={windowWidth}
              handleChangeMenu={handleChangeMenu}
              handleIntake={handleIntake}
              activeMenuItem={activeMenuItem}
              handleSettings={handleSettings}
              handleHelp={handleHelp}
            />
            {activeMenuItem === "users" && (
              <Agents handleCreateLoginContext={handleCreateLoginContext} />
            )}
            {activeMenuItem === "mail" && <Mail />}
            {activeMenuItem === "leads" && (
              <Customers handleFetchCustomers={handleFetchCustomers} />
            )}
            {activeMenuItem === "home" && <Home />}
            {activeMenuItem === "studios" && <Studios />}
            {activeMenuItem === "theaters" && <Theaters />}
            {activeMenuItem === "circuits" && <Circuits />}
            {activeMenuItem === "titles" && <Titles />}
            {activeMenuItem === "campaigns" && <Campaigns />}
            {activeMenuItem === "quotes" && (
              <Quotes handleFetchQuotes={handleFetchQuotes} />
            )}

            <Settings
              handleSettings={handleSettings}
              isSettings={isSettings}
              handleCreateLoginContext={handleCreateLoginContext}
              setLogo={setLogo}
            />
            <Help
              handleHelp={handleHelp}
              isHelp={isHelp}
              isExhibitor={loginContext.role === "Exhibitor"}
            />

            <div className="smooth-image-wrapper">
              <Image
                src={logo}
                width="150px"
                style={{
                  position: "fixed",
                  bottom: "5px",
                  left: "5px",
                }}
                className={`smooth-image image-${
                  logoReady ? "visible" : "hidden"
                }`}
                onLoad={() => setLogoReady(true)}
              />
            </div>
          </div>
        )}
      </AuthContext.Provider>
    </div>
  );
};
export default Dashboard;
