export const tagFields = [
  {
    label: "Rentrack ID",
    key: "rtkTheaterId",
    alternateMatches: ["rentrack ID"],
    fieldType: {
      type: "input",
    },
    example: "5555",
    //   validations: [
    //     {
    //       rule: "required",
    //       errorMessage: "Company is required",
    //       level: "error",
    //     },
    //   ],
  },
];
