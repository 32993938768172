import {
  Form,
  Header,
  Dropdown,
  Button,
  Label,
  Icon,
  Divider,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { states } from "../states";
const AddEditCustomer = (props) => {
  const {
    customer,
    theaterList,
    theater,
    studioList,
    titleList,
    handleChangeFieldString,
    isAdmin,
    isStudio,
    isExhibitor,
    statuses,
    exhibitorStatuses,
    priorities,
    mode,
    errorFields,
    searchBy,
    searchByOptions,
    handleChangeSearchBy,
    searchValue,
    handleTheaterSearchValue,
    searchResults,
    SearchResults,
    searchRadius,
    radiusOptions,
    handleChangeRadius,
    searching,
    handleTheaterSearch,
    handleClearTheaterSelection,
    titleOptions,
  } = props;

  const isDateValid = (dateString) => {
    return !isNaN(new Date(dateString));
  };

  let allowStatus = true;
  if (isExhibitor && mode === "add") {
    allowStatus = false;
  }

  return (
    <Form size="large" id="addeditlead">
      {mode === "edit" && (
        <Form.Field>
          <Header as="h5">ID: {customer.customerId || ""}</Header>
        </Form.Field>
      )}
      {mode === "add" && isExhibitor && (
        <Header subheader="Enter the Contact and Event Information below for the Group Sales lead. Once submitted it will be sent to the Studio for approval." />
      )}
      <Divider horizontal>
        <Header as="h3">
          <Icon name="tag" />
          Contact Information
        </Header>
      </Divider>
      <Form.Input
        error={errorFields.company}
        onChange={handleChangeFieldString}
        id="company"
        placeholder="Group Name"
        label="Group Name"
        value={customer.company || ""}
      />
      <Form.Input
        error={errorFields.industry}
        onChange={handleChangeFieldString}
        id="industry"
        placeholder="Industry"
        label="Industry"
        value={customer.industry || ""}
      />

      <Form.Input
        error={errorFields.firstName}
        onChange={handleChangeFieldString}
        id="firstName"
        placeholder="First Name"
        label="First Name"
        value={customer.firstName || ""}
      />
      <Form.Input
        error={errorFields.lastName}
        onChange={handleChangeFieldString}
        id="lastName"
        placeholder="Last Name"
        label="Last Name"
        value={customer.lastName || ""}
      />

      <Form.Input
        error={errorFields.email}
        onChange={handleChangeFieldString}
        id="email"
        placeholder="Email"
        label="Email"
        value={customer.email || ""}
      />
      <Form.Input
        error={errorFields.phone}
        onChange={handleChangeFieldString}
        id="phone"
        placeholder="Phone"
        label="Phone"
        value={customer.phone || ""}
      />

      <Form.Input
        error={errorFields.address}
        onChange={handleChangeFieldString}
        id="address"
        placeholder="Address"
        label="Address"
        value={customer.address || ""}
      />
      <Form.Input
        error={errorFields.city}
        onChange={handleChangeFieldString}
        id="city"
        placeholder="City"
        label="City"
        value={customer.city || ""}
      />

      <Form.Select
        error={errorFields.state}
        search
        onChange={handleChangeFieldString}
        id="state"
        placeholder="State"
        label="State"
        value={customer.state || ""}
        options={states}
      />
      <Form.Input
        error={errorFields.zipCode}
        onChange={handleChangeFieldString}
        id="zipCode"
        placeholder="Zip Code"
        label="Zip Code"
        value={customer.zipCode || ""}
      />

      <Divider horizontal style={{ marginTop: "30px" }}>
        <Header as="h3">
          <Icon name="tag" />
          Event Information
        </Header>
      </Divider>
      {!isStudio && (
        <Form.Select
          error={errorFields.studioId}
          id="studioId"
          placeholder="Studio"
          label="Studio"
          options={studioList}
          value={customer.studioId}
          onChange={handleChangeFieldString}
        />
      )}
      <Form.Select
        error={errorFields.titleId}
        id="titleId"
        search
        placeholder={
          !customer.studioId
            ? "Select a Studio Before Selecting Title"
            : "Select a Title"
        }
        label="Title"
        options={titleOptions}
        value={customer.titleId}
        onChange={handleChangeFieldString}
      />
      <Form.Field
        error={errorFields.date}
        style={{
          padding: "15px",
          border: "1px solid rgba(0,0,0,0.2)",
          borderRadius: "10px",
          backgroundColor: "rgba(255,255,255,0.25)",
        }}
      >
        <label>Event Date / Time</label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div>
            <DatePicker
              closeOnScroll={true}
              placeholderText="Select a Date and Time"
              showTimeSelect
              timeIntervals={30}
              inline
              dateFormat="MMMM d, yyyy h:mm aa"
              selected={
                isDateValid(customer.date) ? new Date(customer.date) : null
              }
              // onChange={(date) => setDate(date)}
              minDate={new Date()}
              onChange={(date) => handleChangeFieldString("date", date)}
            />
            <b>Selected: </b> {moment(customer.date).format("LLL")}
          </div>
          <div>
            <div
              style={{
                border: "1px solid rgba(0,0,0,0.15)",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  width: "100px",
                  height: "30px",
                  background: "#DE6553",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                  fontWeight: 600,
                }}
              >
                {moment(customer.date).format("MMM").toUpperCase()}
              </div>
              <div
                style={{
                  width: "100px",
                  height: "70px",
                  backgroundColor: "#FAF9F9",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 38,
                  fontWeight: 600,
                }}
              >
                <div style={{ marginBottom: "5px" }}>
                  {moment(customer.date).format("DD")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form.Field>
      <Form.Field
        error={errorFields.theater}
        style={{
          padding: "15px",
          border: "1px solid rgba(0,0,0,0.2)",
          borderRadius: "10px",
          backgroundColor: "rgba(255,255,255,0.25)",
        }}
      >
        <label>Theater</label>
        <span>
          Search Theater By:{" "}
          <Dropdown
            compact
            selection
            disabled={customer.theaterId}
            style={{ marginBottom: "5px" }}
            placeholder="Search By..."
            options={searchByOptions}
            value={searchBy}
            onChange={handleChangeSearchBy}
          />
        </span>
        {searchBy === "zip" ? (
          <p>
            Enter a zip code, choose your search radius, and click Search to
            find theaters in your area.
          </p>
        ) : (
          <p>Enter the name of your theater and click Search.</p>
        )}

        <div
          style={{
            display: "flex",
          }}
        >
          <Dropdown
            disabled={customer.theaterId}
            icon={null}
            error={errorFields.theater}
            style={{ flex: 1 }}
            fluid
            placeholder={
              searchBy === "zip" ? "Enter Zip Code..." : "Enter Theater Name..."
            }
            search
            selection
            searchQuery={searchValue}
            onSearchChange={handleTheaterSearchValue}
            openOnFocus={false}
            open={searchResults && searchResults.length > 0}
          >
            <Dropdown.Menu>
              <SearchResults />
            </Dropdown.Menu>
          </Dropdown>
          {searchBy === "zip" && (
            <Dropdown
              disabled={customer.theaterId}
              compact
              selection
              placeholder="Select Search Radius"
              value={searchRadius}
              options={radiusOptions}
              onChange={handleChangeRadius}
            />
          )}
        </div>
        <Button
          size="large"
          fluid
          style={{
            marginTop: "5px",
          }}
          primary
          content="Search"
          loading={searching}
          disabled={searching || customer.theaterId}
          onClick={handleTheaterSearch}
        />

        {customer.theaterId && (
          <div>
            <span>
              <b>Selected Theater: </b>
            </span>
            <Label
              size="big"
              color="green"
              style={{ marginLeft: "0px", marginTop: "5px" }}
            >
              {theater.name}{" "}
              <Icon name="delete" onClick={handleClearTheaterSelection} />
            </Label>
          </div>
        )}
      </Form.Field>

      <Form.Input
        error={errorFields.groupSize}
        onChange={handleChangeFieldString}
        id="groupSize"
        placeholder="Group Size"
        label="Group Size"
        value={customer.groupSize || ""}
      />

      {/* <Form.Select
          error={errorFields.theaterId}
          lazyLoad
          id="theaterId"
          search
          placeholder="Theater"
          label="Theater"
          options={theaterList}
          value={customer.theaterId}
          onChange={handleChangeFieldString}
        /> */}
      {/* <Form.Input
          placeholder="Theater"
          label="Theater"
          value={theater.name}
        /> */}

      {!isExhibitor && (
        <Form.Field>
          <label>Priority</label>
          <Dropdown
            multiple
            selection
            id="priority"
            placeholder="Select Priority"
            options={priorities}
            value={customer.priority || []}
            onChange={handleChangeFieldString}
          />
        </Form.Field>
      )}
      {allowStatus && (
        <Form.Select
          error={errorFields.status}
          id="status"
          label="Status"
          placeholder="Select Status"
          options={isExhibitor ? exhibitorStatuses : statuses}
          value={customer.status || ""}
          onChange={handleChangeFieldString}
        />
      )}
      <Form.TextArea
        label="Questions / Comments"
        value={customer.comments || ""}
        placeholder="None"
      />
      <Form.Input
        id="leadSource"
        label="Lead Source"
        placeholder="Lead Source"
        value={customer.leadSource || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="dollarValue"
        label="Box Office Gross"
        placeholder="$"
        value={customer.dollarValue || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="auditorium"
        label="Auditorium"
        placeholder="Auditorium"
        value={customer.auditorium || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="seats"
        label="Number of Seats"
        placeholder="Number of Seats"
        value={customer.seats || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="ticketPrice"
        label="Ticket Price"
        placeholder="$"
        value={customer.ticketPrice || ""}
        onChange={handleChangeFieldString}
      />

      {customer.notes && (
        <Form.TextArea
          style={{ width: "100% !important" }}
          id="notes"
          label="Notes"
          placeholder="Notes"
          value={customer.notes || ""}
          onChange={handleChangeFieldString}
        />
      )}
      <Form.TextArea
        style={{ width: "100% !important" }}
        id="quote"
        label="Quote"
        placeholder="Quote"
        value={customer.quote || ""}
        onChange={handleChangeFieldString}
      />
    </Form>
  );
};
export default AddEditCustomer;
