import {
  Form,
  Input,
  Label,
  Header,
  Popup,
  Image,
  Icon,
  Checkbox,
  Divider,
  Dropdown,
} from "semantic-ui-react";
import { FileUploader } from "react-drag-drop-files";
import { SketchPicker } from "react-color";
import CustomEditor from "./Editor/Editor";
const AddEditIntake = (props) => {
  const {
    selectedCampaign,
    titleOptions,
    studiosList,
    fileToUpload,
    posterPreview,
    handleChangePosterArt,
    editMode,
    handleChangeLayout,
    handleChangeFieldString,
    handleChangeLabelColor,
    handleChangeTitle,
    handleChangeStudio,
    loginContext,
    logoPreview,
    logoToUpload,
    handleChangeLogo,
    handleChangeEmailBody,
  } = props;
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
  const radiusOptions = [
    {
      text: "1 Mile",
      value: "1609",
    },
    {
      text: "3 Miles",
      value: "4828",
    },
    {
      text: "5 Miles",
      value: "8047",
    },
    {
      text: "10 Miles",
      value: "16093",
    },
    {
      text: "15 Miles",
      value: "24140",
    },
    {
      text: "20 Miles",
      value: "32187",
    },
    {
      text: "25 Miles",
      value: "40234",
    },
  ];

  return (
    <Form size="large">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            width: "300px",
          }}
          onClick={() => handleChangeLayout("layout1")}
        >
          <Form.Checkbox
            radio
            checked={selectedCampaign.layout === "layout1"}
            onChange={() => handleChangeLayout("layout1")}
          />
          <div style={{ padding: "10px" }}>
            <Header as="h3">
              Layout #1
              <Header.Subheader>
                Designed for poster art, side-by-side with the intake form on
                the right, with no overlap.
              </Header.Subheader>
            </Header>
            <Image size="small" src="./images/layout1.png" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            width: "300px",
          }}
          onClick={() => handleChangeLayout("layout2")}
        >
          <Form.Checkbox
            radio
            checked={selectedCampaign.layout === "layout2"}
            onChange={() => handleChangeLayout("layout2")}
          />
          <div style={{ padding: "10px" }}>
            <Header as="h3">
              Layout #2
              <Header.Subheader>
                Artwork as background image, with the intake form partially
                overlaid, on the right.
              </Header.Subheader>
            </Header>
            <Image size="small" src="./images/layout2.png" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            width: "300px",
          }}
          onClick={() => handleChangeLayout("layout3")}
        >
          <Form.Checkbox
            radio
            checked={selectedCampaign.layout === "layout3"}
            onChange={() => handleChangeLayout("layout3")}
          />
          <div style={{ padding: "10px" }}>
            <Header as="h3">
              Layout #3
              <Header.Subheader>
                Artwork as background image, with the intake form partially
                overlaid, on the left.
              </Header.Subheader>
            </Header>
            <Image size="small" src="./images/layout3.png" />
          </div>
        </div>
      </div>
      {!loginContext.studioId && (
        <Form.Select
          search
          disabled={editMode}
          id="studioId"
          label={
            <span style={{ fontWeight: 600 }}>
              Studio{" "}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Select the Studio for the intake campaign."
              />
            </span>
          }
          placeholder="Select Studio"
          value={selectedCampaign.studioId}
          options={studiosList}
          onChange={handleChangeStudio}
        />
      )}
      <Form.Select
        multiple
        search
        id="titleId"
        label={
          <span style={{ fontWeight: 600 }}>
            Title{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Select the Title for the intake campaign."
            />
          </span>
        }
        placeholder="Select a Title"
        value={selectedCampaign.titleId || []}
        options={titleOptions}
        onChange={handleChangeTitle}
      />
      <Divider
        horizontal
        section
        style={{
          marginTop: "4rem",
          marginBottom: "2rem",
        }}
      >
        <Header as="h3">
          <Icon name="pencil" />
          Intake Form Fields
        </Header>
      </Divider>
      <Form.TextArea
        id="gateway"
        label={
          <span style={{ fontWeight: 600 }}>
            Gateway Text{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="If Gateway text is provided, this will force a modal gateway with Agree/Decline options before the intake form can be accessed."
            />
          </span>
        }
        placeholder="This text will appear before the form..."
        value={selectedCampaign.gateway}
        onChange={handleChangeFieldString}
      />
      <Form.TextArea
        id="gatewayDecline"
        label={
          <span style={{ fontWeight: 600 }}>
            Gateway Decline Message{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="If a user declines to accept the terms on the gateway, this message will appear."
            />
          </span>
        }
        placeholder="This text will appear after declining terms..."
        value={selectedCampaign.gatewayDecline}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        label={
          <span style={{ fontWeight: 600 }}>
            Form Heading{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Form Heading appears at the top of the Intake form, and replaces the default text."
            />
          </span>
        }
        id="formHeading"
        value={selectedCampaign.formHeading || ""}
        placeholder="Request a Screening Quote"
        onChange={handleChangeFieldString}
      />
      <Form.Input
        label={
          <span style={{ fontWeight: 600 }}>
            Privacy Policy Link{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Includes an external link to your company's privacy policy on the Intake form. Must include https://..."
            />
          </span>
        }
        id="privacyLink"
        value={selectedCampaign.privacyLink || ""}
        placeholder="https://www.example.com/privacy"
        onChange={handleChangeFieldString}
      />
      <Form.TextArea
        id="terms"
        label={
          <span style={{ fontWeight: 600 }}>
            Terms of Service{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Terms of Service text will replace the default text on the intake form, accompanied by a checkbox that is required before the form can be submitted."
            />
          </span>
        }
        placeholder="I agree to receive contact..."
        value={selectedCampaign.terms}
        onChange={handleChangeFieldString}
      />

      <Form.TextArea
        id="thankYou"
        label={
          <span style={{ fontWeight: 600 }}>
            Thank You Message{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Thank You text will replace the default message that is displayed on the intake page after the form has been submitted."
            />
          </span>
        }
        placeholder="Thank you for your request..."
        value={selectedCampaign.thankYou}
        onChange={handleChangeFieldString}
      />
      <Form.TextArea
        id="groupPolicy"
        label={
          <span style={{ fontWeight: 600 }}>
            Group Sales Policy{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Add any information here that the Exhibitor should have to refer to when handling leads. Can include plain text, html, or links to external resources."
            />
          </span>
        }
        placeholder="Group Sales Policy..."
        value={selectedCampaign.groupPolicy}
        onChange={handleChangeFieldString}
      />
      <Form.TextArea
        id="expiredText"
        label={
          <span style={{ fontWeight: 600 }}>
            Expired Campaign Text{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Once the group sales campaign has ended, this text will be presented in place of the intake form if anyone visits the campaign URL. Can include plain text, html, or links."
            />
          </span>
        }
        placeholder="Expired Campaign Text..."
        value={selectedCampaign.expiredText}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        label={
          <span style={{ fontWeight: 600 }}>
            Trailer URL{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Link to the title's trailer which will force the trailer to appear on the intake page."
            />
          </span>
        }
        id="trailerUrl"
        value={selectedCampaign.trailerUrl || ""}
        placeholder="https://..."
        onChange={handleChangeFieldString}
      />
      <Divider
        horizontal
        section
        style={{
          marginTop: "4rem",
          marginBottom: "2rem",
        }}
      >
        <Header as="h3">
          <Icon name="mail" />
          Email Options
        </Header>
      </Divider>
      <Form.Input
        id="emailSubject"
        label={
          <span style={{ fontWeight: 600 }}>
            Email Subject{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Replaces the default subject line of the customer confirmation email. NOTE: Be careful not to use any words or phrases in the subject line that may trigger spam blockers."
            />
          </span>
        }
        placeholder="Thank you for your submission!"
        value={selectedCampaign.emailSubject}
        onChange={handleChangeFieldString}
      />
      <Form.Field>
        <label>
          <span style={{ fontWeight: 600, fontSize: "1.14285714rem" }}>
            Email Body{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Overrides the default content for the confirmation email that users will receive after submitting the intake form."
            />
          </span>
        </label>
        <CustomEditor
          emailText={selectedCampaign.emailText}
          handleChangeEmailBody={handleChangeEmailBody}
        />
      </Form.Field>
      {/* <Form.TextArea
        id="emailText"
        label={
          <span style={{ fontWeight: 600 }}>
            Email Body{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Overrides the default content for the confirmation email that users will receive after submitting the intake form."
            />
          </span>
        }
        placeholder="Thank you for submitting the form..."
        value={selectedCampaign.emailText}
        onChange={handleChangeFieldString}
      /> */}
      <Form.Input
        id="footerText1"
        label={
          <span style={{ fontWeight: 600 }}>
            Email Footer Text 1{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="First line of text to appear in the footer of the confirmation email the customer receives upon form submission. Example: Full legal company name."
            />
          </span>
        }
        placeholder="Company Name"
        value={selectedCampaign.footerText1}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="footerText2"
        label={
          <span style={{ fontWeight: 600 }}>
            Email Footer Text 2{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Second line of text to appear in the footer of the confirmation email the customer receives upon form submission. Example: City, State."
            />
          </span>
        }
        placeholder="City, State"
        value={selectedCampaign.footerText2}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="footerText3"
        label={
          <span style={{ fontWeight: 600 }}>
            Email Footer Text 3{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Third line of text to appear in the footer of the confirmation email the customer receives upon form submission. Example: company website."
            />
          </span>
        }
        placeholder="www.example.com"
        value={selectedCampaign.footerText3}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="contactLink"
        label={
          <span style={{ fontWeight: 600 }}>
            Contact Us Link{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Link to a contact page on your website. Goes in the footer of the confirmation email and replaces the default link."
            />
          </span>
        }
        placeholder="https://www.example.com/contactus"
        value={selectedCampaign.contactLink}
        onChange={handleChangeFieldString}
      />

      <Divider
        horizontal
        section
        style={{
          marginTop: "4rem",
          marginBottom: "2rem",
        }}
      >
        <Header as="h3">
          <Icon name="options" />
          Intake Form Options
        </Header>
      </Divider>

      <Form.Field>
        <label>
          <span style={{ fontWeight: 600 }}>
            Default Search Radius{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Change the default radius for theater search."
            />
          </span>
        </label>
        <Dropdown
          selection
          id="defaultRadius"
          options={radiusOptions}
          value={selectedCampaign.defaultRadius}
          onChange={handleChangeFieldString}
        />
      </Form.Field>

      <Form.Field>
        <label>
          <span style={{ fontWeight: 600 }}>
            Near Date Selection Threshold{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Controls the numbers of days forward from the current day that cannot be selected on the intake form."
            />
          </span>
        </label>
        <Input
          id="nearSelectionDays"
          fluid
          onChange={handleChangeFieldString}
          value={selectedCampaign.nearSelectionDays}
        />
      </Form.Field>

      <Form.Field>
        <label>
          <span style={{ fontWeight: 600 }}>
            Prevent Selection of Near Dates{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Enable this to prevent users from selecting an event date that is any time in the next 7 days."
            />
          </span>
        </label>
        <Checkbox
          id="preventNearSelection"
          toggle
          onChange={handleChangeFieldString}
          checked={selectedCampaign.preventNearSelection}
        />
      </Form.Field>

      <Form.Field>
        <label>
          <span style={{ fontWeight: 600 }}>
            Comments Field{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Enable this to display a free-text comments field on the intake form."
            />
          </span>
        </label>
        <Checkbox
          id="enableComments"
          toggle
          onChange={handleChangeFieldString}
          checked={selectedCampaign.enableComments}
        />
      </Form.Field>

      <Form.Field>
        <label>
          <span style={{ fontWeight: 600 }}>
            Markets Only{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Enable this to force selection of theater market instead of theater location on the intake form. A custom list of markets must be provided."
            />
          </span>
        </label>
        <Checkbox
          id="marketsOnly"
          toggle
          onChange={handleChangeFieldString}
          checked={selectedCampaign.marketsOnly}
        />
      </Form.Field>

      {selectedCampaign.marketsOnly && (
        <Form.TextArea
          id="markets"
          label={
            <span style={{ fontWeight: 600 }}>
              Markets List{" "}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Add a comma-separated list of market names for visitors to select from on the intake form."
              />
            </span>
          }
          placeholder="New York,Los Angeles,Chicago..."
          value={selectedCampaign.markets}
          onChange={handleChangeFieldString}
        />
      )}

      <Form.Field>
        <label>
          <span style={{ fontWeight: 600 }}>
            Custom Theater List{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Enable this if you are providing a custom list of theaters for this campaign. If enabled, only theaters tagged with this campaign will be available to select on the Intake form. If disabled, all theaters will be available to select."
            />
          </span>
        </label>
        <Checkbox
          id="limitedTheaters"
          toggle
          onChange={handleChangeFieldString}
          checked={selectedCampaign.limitedTheaters}
        />
      </Form.Field>
      <Form.Field>
        <label>
          <span style={{ fontWeight: 600 }}>
            Disable Confirmation Email{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="By default, a confirmation email is sent to the user who fills out the intake form. Toggle this on to disable sending email."
            />
          </span>
        </label>
        <Checkbox
          id="disableEmail"
          toggle
          onChange={handleChangeFieldString}
          checked={selectedCampaign.disableEmail}
        />
      </Form.Field>

      <Divider
        horizontal
        section
        style={{
          marginTop: "4rem",
          marginBottom: "2rem",
        }}
      >
        <Header as="h3">
          <Icon name="paint brush" />
          Media / Theme
        </Header>
      </Divider>

      <Form.Field>
        <label>
          {" "}
          <span style={{ fontWeight: 600 }}>
            Label Color{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Label color will replace the default color for headers and labels on the intake form."
            />
          </span>
        </label>
        <SketchPicker
          color={selectedCampaign.labelColor || ""}
          onChangeComplete={handleChangeLabelColor}
        />
      </Form.Field>
      <Form.Field>
        <label>
          {" "}
          <span style={{ fontWeight: 600 }}>
            Poster / Background Artwork{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Artwork should be an image file approximately 1000x1500 pixels (poster art) for Layout #1, or 1920x1080 pixels for Layout #2 (background image)."
            />
          </span>
        </label>
        <div style={{ display: "flex" }}>
          {/* <Image
            size="small"
            src={
              fileToUpload
                ? URL.createObjectURL(fileToUpload)
                : posterPreview
                ? posterPreview
                : "/images/placeholder.png"
            }
          /> */}
          <FileUploader
            handleChange={handleChangePosterArt}
            name="file"
            types={fileTypes}
            hoverTitle=" "
            children={
              <div
                style={{
                  cursor: "pointer",
                  borderStyle: "dashed",
                  flex: 1,
                  height: "100%",
                  width: "600px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <Image
                  size="small"
                  src={
                    fileToUpload
                      ? URL.createObjectURL(fileToUpload)
                      : posterPreview
                      ? posterPreview
                      : "/images/placeholder.png"
                  }
                />
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Header>
                    <Icon name="upload" />
                    <Header.Content>
                      Drop a file here
                      <Header.Subheader>Or click to browse</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
              </div>
            }
          />
        </div>
      </Form.Field>
      <Form.Field>
        <label>
          {" "}
          <span style={{ fontWeight: 600 }}>
            Logo Artwork{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Logo will replace Title Text on Intake Form."
            />
          </span>
        </label>
        <div style={{ display: "flex" }}>
          {/* <Image
            size="small"
            src={
              logoToUpload
                ? URL.createObjectURL(logoToUpload)
                : logoPreview
                ? logoPreview
                : "/images/placeholder.png"
            }
          /> */}
          <FileUploader
            handleChange={handleChangeLogo}
            name="file"
            types={fileTypes}
            hoverTitle=" "
            children={
              <div
                style={{
                  cursor: "pointer",
                  borderStyle: "dashed",
                  flex: 1,
                  height: "100%",
                  width: "600px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <Image
                  size="small"
                  src={
                    logoToUpload
                      ? URL.createObjectURL(logoToUpload)
                      : logoPreview
                      ? logoPreview
                      : "/images/placeholder.png"
                  }
                />
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Header>
                    <Icon name="upload" />
                    <Header.Content>
                      Drop a file here
                      <Header.Subheader>Or click to browse</Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
              </div>
            }
          />
        </div>
      </Form.Field>
    </Form>
  );
};
export default AddEditIntake;
